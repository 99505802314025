import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Cookies from "universal-cookie";
import {
  Login,
  ResetPassword,
  MainLayout,
  Dashboard,
  ForgotPassword,
  Recovery,
  OTP,
  Logout,
  Wallet,
  SendSMS,
  SMShistory,
  Personalized,
  AddressGroup,
  ViewList,
  SmsReport,
  ScheduledReport,
  WalletReport,
  PaymentRequest,
  Settings,
  Missing,
  ViewSenderID,
} from "./components";

import { AuthorizeUser, ProtectRoute } from "./middleware/auth";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import io from "socket.io-client";

// const Login = React.lazy(() => import('./pages/login/Login'))
// const ResetPassword = React.lazy(() => import('./pages/login/Login'))
// const ForgotPassword = React.lazy(() => import('./pages/login/Login'))

const Test = React.lazy(() => import("./pages/login/test"));

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth/logout" element={<Logout />} />
        <Route
          path="/login/test"
          element={
            <AuthorizeUser>
              <Test />
            </AuthorizeUser>
          }
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route path="/recovery" element={<Recovery />} />
        <Route
          path="/auth/otp"
          element={
            <AuthorizeUser>
              <OTP />
            </AuthorizeUser>
          }
        />
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <AuthorizeUser>
              <MainLayout />
            </AuthorizeUser>
          }
        >
          <Route
            index
            element={
              <AuthorizeUser>
                <Dashboard />
              </AuthorizeUser>
            }
          />
          <Route
            path="wallet"
            element={
              <AuthorizeUser>
                <Wallet />
              </AuthorizeUser>
            }
          />
          <Route
            path="wallet-transactions"
            element={
              <AuthorizeUser>
                <WalletReport />
              </AuthorizeUser>
            }
          />
          <Route
            path="payment-history"
            element={
              <AuthorizeUser>
                <PaymentRequest />
              </AuthorizeUser>
            }
          />
          <Route
            path="send-sms"
            element={
              <AuthorizeUser>
                <SendSMS />
              </AuthorizeUser>
            }
          />
          <Route
            path="sms-history"
            element={
              <AuthorizeUser>
                <SMShistory />
              </AuthorizeUser>
            }
          />
          <Route
            path="sms-report"
            element={
              <AuthorizeUser>
                <SmsReport />
              </AuthorizeUser>
            }
          />
          <Route
            path="scheduled-report"
            element={
              <AuthorizeUser>
                <ScheduledReport />
              </AuthorizeUser>
            }
          />
          <Route
            path="personalised-sms"
            element={
              <AuthorizeUser>
                <Personalized />
              </AuthorizeUser>
            }
          />
          <Route
            path="address-book"
            element={
              <AuthorizeUser>
                <AddressGroup />
              </AuthorizeUser>
            }
          />
          <Route
            path="viewAddresslist"
            element={
              <AuthorizeUser>
                <ViewList />
              </AuthorizeUser>
            }
          />
          <Route
            path="settings"
            element={
              <AuthorizeUser>
                <Settings />
              </AuthorizeUser>
            }
          />
          <Route
            path="sender-id"
            element={
              <AuthorizeUser>
                <ViewSenderID />
              </AuthorizeUser>
            }
          />
        </Route>
        <Route path="*" element={<Missing />} />
      </Routes>
    </Router>
  );
}

export default App;
