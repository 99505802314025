import React, {useState} from 'react'
// import '../App.css';
import { Link } from 'react-router-dom'
import CustomInput from './CustomInput'
import styles from "./index.css"



const ForgotPassword = () => {
  const [isSignup, setIsSignup] = useState(false)
  const [form, setForm] = useState()

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value});
  }

  const switchMode =()=>{
    setIsSignup((prevIsSignup)=>!prevIsSignup)
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    console.log(form);
  }

  return (
    <div className="logpage bg-primaryC py-4 min-h-screen">
      <div className={`glass my-5 ${isSignup && 'md:w-[450px] sm:w-[450px]'}  w-[320px] mx-auto `}>
        <h3 className='text-center text-3xl mb-2'>Forgot Password</h3>
        <p className='text-center mb-4 xs:text-[15px]  text-[12px]'>Enter your email to reset your password</p>
        <form onSubmit={handleSubmit}>
          
        <CustomInput type="text" label='Email Address' id='email' required='required' onChange={handleChange} />
        
        <div className="mb-3 text-right text-xs">
          {/* {!isSignup && <Link to='/forgot-password' className='font-bold'>Login</Link>} */}
        </div>
        <button className='rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-100 text-center text-decoration-none fs-5' type='submit'>Send Link</button>
        <div className='text-xs mt-3'>
          <p>
              ALready have an  account?
              <Link to='/login' className='font-bold cursor-pointer text-right'> Login</Link>
            </p>
        </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword


