import React, { useEffect, useState } from "react";
import { Tabs, Checkbox, Card, Space, Button } from "antd";
import { loggedInUsers } from "../../store/store";
import { getAllSenderId } from "../../libs/dashboard";

var CryptoJS = require("crypto-js");
const { TabPane } = Tabs;
// const onChange = (key) => {
//   console.log(key);
// };
let promo = [];
// let sidvalue1;

const ViewSenderID = () => {
  const [sIDP, setSIDP] = useState([]);
  const [sIDT, setSIDT] = useState([]);
  const { userdata, senderIDTrans, senderIDPromo } = loggedInUsers(
    (state) => state.auth
  );
  const setSenderIDTrans = loggedInUsers((state) => state.setSenderIDTrans);
  const setSenderIDPromo = loggedInUsers((state) => state.setSenderIDPromo);

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    getSenderid();
  }, []);

  const getSenderid = async () => {
    const getSID = await getAllSenderId({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data } = getSID;

    let sidvalue1 = data.data.promotional;
    let sidvalue2 = data.data.transactional;

    setSIDP(sidvalue1);
    setSIDT(sidvalue2);

    return sidvalue1;
  };

  const convertSIDS = (sidvalue) => {
    let objectSenderIds = [];

    for (let id of sidvalue) {
      let objectArray = { value: id, label: id };
      objectSenderIds.push(objectArray);
    }

    // console.log(objectSenderIds);
    return objectSenderIds;
  };

  const getPromo = () => {
    return (
      <>
        {sIDP.map((value, index) => (
          <p key={index}>
            <Checkbox>{value}</Checkbox>
          </p>
        ))}
      </>
    );
  };

  const getTrans = () => {
    return (
      <>
        {sIDT.map((value, index) => (
          <p key={index}>
            <Checkbox>{value}</Checkbox>
          </p>
        ))}
      </>
    );
  };

  const items = [
    {
      key: "1",
      label: `Promotional`,
      children: getPromo(),
    },
    {
      key: "2",
      label: `Transactional`,
      children: getTrans(),
    },
  ];

  const mailto = () => {
    window.open(
      `mailto:vas@broadbased.net?subject=New%20Sender%20ID%20Request&body=Dear%20Team%2C%0D%0A%0D%0AI%20want%20to%20register%20my%20sender%20Id%20%5BSENDER%20ID%20NAME%5D%20for%20%5BPromotion%20or%20Transactional%5D%20purposes.%20Kindly%20advise%20on%20what%20steps%20to%20take%20next.%0D%0A%0D%0AThanks`,
      "_blank"
    );
  };
  const download = () => {
    window.open(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/downloadSmsRequirement`,
      "_blank"
    );
  };
  return (
    <div>
      <p className="text-xl font-semibold mb-4">View Sender ID</p>
      <Space direction="horizontal" size={16}>
        <Card
          style={{
            width: 700,
            minHeight: 250,
          }}
        >
          <Tabs defaultActiveKey="1" items={items} />
        </Card>
        <Card
          style={{
            width: 300,
            minHeight: 250,
          }}
        >
          <div>
            <p className="text-lg font-semibold mb-4 ">Request for Sender ID</p>
            <p className="">
              To request for Sender ID to be whitelisted, the following
              <span className="font-bold cursor-pointer" onClick={download}>
                {" "}
                requirements
              </span>{" "}
              must be sent when making a request to register a sender ID.
            </p>
            <Button
              type="primary"
              onClick={mailto}
              className={`rounded-1xl mt-2 float-right bg-indigo-500 text-decoration-none fs-8 hover:scale-105 duration-300 text-white              font-medium w-22  text-center `}
            >
              Request Now
            </Button>
          </div>
        </Card>
      </Space>
    </div>
  );
};

export default ViewSenderID;
