import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore, loggedInUsers } from "../../store/store";
// import { getSocketInstance } from "../../libs/socketioClient";

// export function Logout() {
//   const navigate = useNavigate();
//   const setActiveUser = loggedInUsers((state) => state.setActiveUser);
//   const setAkpaEgo = loggedInUsers((state) => state.setAkpaEgo);
//   const toggleActiveMode = loggedInUsers((state) => state.toggleActiveMode);
//   // const activeMode = loggedInUsers((state) => state.auth.active);
//   setActiveUser();
//   setAkpaEgo();
//   toggleActiveMode(false);
//   // console.log(activeMode);

//   useEffect(() => {
//     navigate("/login");
//   });
// }

// export default Logout;

const Logout = () => {
  const navigate = useNavigate();
  const setActiveUser = loggedInUsers((state) => state.setActiveUser);
  const setAkpaEgo = loggedInUsers((state) => state.setAkpaEgo);
  const toggleActiveMode = loggedInUsers((state) => state.toggleActiveMode);
  const toggleSocketMode = loggedInUsers((state) => state.toggleSocketMode);
  const setSenderIDTrans = loggedInUsers((state) => state.setSenderIDTrans);
  const setSenderIDPromo = loggedInUsers((state) => state.setSenderIDPromo);
  // const activeMode = loggedInUsers((state) => state.auth.active);
  setActiveUser(null);
  setAkpaEgo(null);
  toggleActiveMode(false);
  toggleSocketMode("");
  setSenderIDTrans(null);
  setSenderIDPromo(null);
  // getSocketInstance().disconnect();
  // console.log(activeMode);

  useEffect(() => {
    navigate("/");
    // window.location.replace("/login");
  });
  return;
};

export default Logout;
