import Cookies from "universal-cookie";
import { loggedInUsers } from "../store/store";

var CryptoJS = require("crypto-js");
const cookie = new Cookies();

// const { userdata } = loggedInUsers((state) => state.auth);
// // Decrypt
// var getUser = CryptoJS.AES.decrypt(
//   userdata,
//   process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
// );
// var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

// cookie
// if (cookie.get(userD?.email)) {
//   cookie.set(userD?.email, ["Hello", "welcome", "Thanks"]);
//   console.log("cookie.get(userD?.email)");
// }
// const cData = cookie.get(userD?.email);
// // Storage
// // Display

// let data = "New Data"

// const go = notify(cData,data)

export function notifyMe(oldData, data) {
  const MAX_NOTIFICATION = 5;

  const arr = cookie.get(oldData);
  const newarr = arr.filter((str) => str !== "");

  newarr.unshift(data);

  if (newarr.length > MAX_NOTIFICATION) {
    newarr.pop();
  }
  //   console.log(arr);
  cookie.set(oldData, newarr);
}
