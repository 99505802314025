import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Card,
  Input,
  Radio,
  DatePicker,
  Select,
  Space,
  message,
  Modal,
  Upload,
  Switch,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { count } from "sms-length";
import { validateSendSMS } from "../../libs/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  allList,
  getAllSenderId,
  getSMSUser,
  previewSMS,
  sendSMS,
} from "../../libs/dashboard";
import { loggedInUsers } from "../../store/store";
import Cookies from "universal-cookie";
import { notifyMe } from "../notifyCook";

var CryptoJS = require("crypto-js");
const { TextArea } = Input;
const { Dragger } = Upload;

var userGroup;
let userGroupNames;
let tableDataSource;

const SendSMS = () => {
  const [recipientType, setRecipientType] = useState("typeNumbers");
  const [uploadId, setUploadId] = useState("");
  const [msgTypeS, setMsgTypeS] = useState("promotional");
  const [flipSchedule, setFlipSchedule] = useState(false);

  const { userdata, senderIDTrans, senderIDPromo, trnsMsg } = loggedInUsers(
    (state) => state.auth
  );
  const setSenderIDTrans = loggedInUsers((state) => state.setSenderIDTrans);
  const setSenderIDPromo = loggedInUsers((state) => state.setSenderIDPromo);
  const setTrnsMsg = loggedInUsers((state) => state.setTrnsMsg);

  const { notifica8 } = loggedInUsers((state) => state.notifica8);
  const setNotifica8 = loggedInUsers((state) => state.setNotifica8);

  const [isPreview, setIsPreview] = useState("yes");
  const [isPreviewBtn, setIsPreviewBtn] = useState(false);
  const [smsPreview, setSmsPreview] = useState({});
  const [senderIDss, setSenderIDss] = useState([]);

  const [groupValue, setGroupValue] = useState(null);

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  // cookie
  const cookie = new Cookies();

  useEffect(() => {
    getSenderid();
    getContactGroup();
    formik.setFieldValue("messageType", msgTypeS);
  }, []);

  const getSenderid = async () => {
    const getSID = await getAllSenderId({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data } = getSID;

    const smsUser = await getSMSUser({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data: datasms } = smsUser;
    const res = datasms.data.approvedForTransactionalSmses;
    setTrnsMsg(res);
    // console.log("Approved for Trans", trnsMsg);

    // return data.data;

    let sidvalue1 = data.data.promotional;
    let sidvalue2 = data.data.transactional;
    let objectSenderIdsPromo = convertSIDS(sidvalue1);
    let objectSenderIdsTrans = convertSIDS(sidvalue2);

    // const objData = { val: sidvalue, lbl: sidlabel },
    //   keys = { val: "value", lbl: "label" },
    //   result = Object.entries(objData).reduce(
    //     (r, [key, array]) => array.map((v, i) => ({ ...r[i], [keys[key]]: v })),
    //     []
    //   );

    // setSenderIDss(sidvalue);
    setSenderIDTrans(objectSenderIdsTrans);
    setSenderIDPromo(objectSenderIdsPromo);

    return objectSenderIdsPromo;
  };

  // getAllGroups
  const getContactGroup = async () => {
    const getGrp = await allList({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    // console.log("getGpr", getGrp);
    // setFetchGroup(getGrp);
    userGroup = getGrp.data.data;
    userGroupNames = userGroup.map((group) => {
      return { value: group.name, label: group.name };
    });
    // tableDataSource = userGroup.map((group) => {
    //   return {
    //     key: group.id,
    //     name: group.name,
    //     nor: group.totalNumberOfContacts,
    //   };
    // });
    // setDataSource(tableDataSource);
  };

  const groupID = (value) => {
    // console.log("groupID", value);
    if (value) {
      let gId = userGroup.find((item) => item.name === value);
      formik.setFieldValue("groupId", gId.id);
      setGroupValue(value);
      // console.log(gId.id);
    } else {
      formik.setFieldValue("groupId");
      // console.log("empty");
    }
  };

  const optionsSelector = [
    {
      label: "Type",
      value: "typeNumbers",
    },
    {
      label: "Upload",
      value: "upload",
    },
    {
      label: "Address Book",
      value: "contact_group",
    },
  ];

  const msgType = [
    {
      label: "Promotional",
      value: "promotional",
    },
    {
      label: "Transactional",
      value: "transactional",
      disabled: !trnsMsg,
    },
  ];

  const convertSIDS = (sidvalue) => {
    let objectSenderIds = [];

    for (let id of sidvalue) {
      let objectArray = { value: id, label: id };
      objectSenderIds.push(objectArray);
    }

    // console.log(objectSenderIds);
    return objectSenderIds;
  };

  const msgTypeChange = ({ target: { value } }) => {
    formik.setFieldValue("messageType", value);
    // console.log(value);
    setMsgTypeS(value);
  };

  const contactType = async ({ target: { value } }) => {
    if (value === "typeNumbers") {
      formik.setFieldValue("recipientType", value);
      formik.setFieldValue("file");
      setUploadId("");
    } else if (value === "upload") {
      showModal();
      formik.setFieldValue("typedNumbers");
      formik.setFieldValue("recipientType", value);
    } else if (value === "contact_group") {
      formik.setFieldValue("typedNumbers");
      formik.setFieldValue("recipientType", value);
    }
    setRecipientType(value);
    // console.log(value);
  };

  // MODAL Starts Here
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = ({ id }) => {
    if (!id) {
      handleCancel();
    } else {
      setConfirmLoading(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setConfirmLoading(false);
      }, 1000);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setRecipientType("typeNumbers");
    setUploadId("");
    formik.setFieldValue("recipientType", "typeNumbers");
  };

  // Modal Ends Here

  // Upload File
  const headers = { authorization: `Bearer ${userD?.bearerToken}` };

  const props = {
    name: "file",
    multiple: false,
    action: `${process.env.REACT_APP_SMSMODULE_BASEURL}/uploads/uploadFileForSingleMessage?userId=${userD?.email}`,
    headers,
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploadId(info.file.name);
        formik.setFieldValue("fileUploaded", true);
        formik.setFieldValue("uploadedFileName", response.data.uploadFileName);
        setTimeout(() => {
          handleOk({ id: info.file.name });
        }, 500);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        // console.log("response", response);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // End of upload

  const reader = new FileReader();

  const handleFileInput = (e) => {
    var fileInput = document.getElementById("file");
    var filePath = fileInput.value;
    var allowedExtensions = /(\.txt|\.csv)$/i;
    if (!allowedExtensions.exec(filePath)) {
      toast.error("Please upload file having extensions .txt/.csv. only.");
      fileInput.value = "";
      return false;
    } else {
      // console.log(fileInput);
      // const read = reader.readAsText(fileInput);
      readFile();
    }
  };

  function readFile() {
    // This is working ///
    // const content = document.querySelector(".content");
    const [file] = document.querySelector("input[type=file]").files;
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // this will then display a text file
        // content.innerText = reader.result;
        // console.log(reader.result);
        const fileOutput = reader.result;
        const filtered = filter(fileOutput);
        // console.log(filtered);
        formik.setFieldValue("file", filtered);
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  }

  function filter(fileOutput) {
    let myString = fileOutput;
    if (myString.includes(",")) {
      const splited = myString.split(",\r\n");
      if (splited[splited.length - 1] == "") splited.pop();
      // console.log(splited);
      return splited;
    } else {
      const splited = myString.split("\r\n");
      if (splited[splited.length - 1] == "") splited.pop();
      // console.log(splited);
      return splited;
    }
  }

  const numbaSplit = (e) => {
    const myString = e.target.value;
    const splited = myString?.split(",\n");
    if (splited[splited.length - 1] == "") splited.pop();
    // console.log(splited);
    formik.setFieldValue("typedNumbers", splited);
  };

  const resetForm = () => {
    formik.resetForm();
    setIsPreviewBtn(false);
    setRecipientType("typeNumbers");
    formik.setFieldValue("messageType", msgTypeS);
    formik.setFieldValue("scheduleDateTime");
    setUploadId("");
    setFlipSchedule(false);
    setGroupValue(null);
  };

  const { RangePicker } = DatePicker;

  // Schedule date
  const onDateChange = (value, dateString) => {
    // console.log('Selected Time: ', value);
    console.log("Formatted Selected Time: ", dateString);
    formik.setFieldValue("isScheduled", true);
    formik.setFieldValue("scheduleDateTime", dateString);
  };

  const switchSchedule = () => {
    setFlipSchedule(!flipSchedule);
    if (flipSchedule === false) formik.setFieldValue("scheduleDateTime", "");
    formik.setFieldValue("isScheduled", !flipSchedule);
    formik.setFieldValue("flipSchedule", !flipSchedule);
  };

  const senderID = [
    {
      value: senderIDss[0],
      label: senderIDss[0],
    },
    {
      value: senderIDss[1],
      label: senderIDss[1],
    },
  ];

  // console.log(senderID);

  const selectSID = (value) => {
    // console.log(`selected ${value}`);
    formik.setFieldValue("senderID", value);
    // console.log(document.);
    // const userInput = document.querySelector("select");
    const cus = (document.getElementById("RSID").value = "");
    // console.log(cus);
  };

  const formik = useFormik({
    initialValues: {
      senderID: "",
      typedNumbers: "",
      smsMessage: "",
      messageType: "",
      recipientType,
      fileUploaded: false,
      uploadedFileName: "",
      isScheduled: false,
      scheduleDateTime: "",
      flipSchedule: false,
      groupId: "",
    },
    validate: validateSendSMS,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = Object.assign(
        values,
        { userId: userD?.email || "" },
        { bearerToken: userD?.bearerToken || "" }
      );
      // console.log(values);
      // return
      try {
        if (!isPreviewBtn) {
          const smsResp = await previewSMS(values);
          const {
            data: { data },
          } = smsResp;
          // console.log(data);
          setIsPreviewBtn(true);
          setSmsPreview(data);
        } else if (isPreviewBtn) {
          const smsResp = await sendSMS(values);
          const {
            data: { data },
          } = smsResp;
          console.log(data);
          const msg = `${
            values.isScheduled
              ? `Your SMS has been Scheduled for ${values.scheduleDateTime}`
              : "SMS sent successfully"
          }`;
          toast.success(msg);
          resetForm();
          notifyMe(userD?.email, msg);
        }
      } catch (error) {
        console.error("Error Sending SMS");
      }
    },
  });

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4">
        Send SMS - Broadbased SMS Solution
      </h3>
      <div>
        <Card
          title="Compose SMS"
          bordered={false}
          style={{
            width: `85%`,
          }}
        >
          <form onSubmit={formik.handleSubmit} id="form">
            <div className="flex flex-col gap-3 pl-4">
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">SMS Route:</label>

                <Radio.Group
                  options={msgType}
                  onChange={msgTypeChange}
                  value={msgTypeS}
                  optionType="button"
                  buttonStyle="solid"
                  disabled={isPreviewBtn ? true : false}
                />
              </div>
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">From:</label>
                <Space.Compact id="RSID">
                  {msgTypeS === "promotional" ? (
                    <Select
                      // defaultValue="Zhejiang"
                      allowClear="true"
                      placeholder="Sender ID"
                      options={senderIDPromo}
                      onChange={selectSID}
                      disabled={isPreviewBtn ? true : false}
                    />
                  ) : (
                    <Select
                      // defaultValue="Zhejiang"
                      className="w-60 font-poppins"
                      allowClear="true"
                      placeholder="Sender ID"
                      options={senderIDTrans}
                      onChange={selectSID}
                      disabled={isPreviewBtn ? true : false}
                    />
                  )}
                  {msgTypeS === "promotional" && (
                    <Input
                      placeholder="Sender Name"
                      required="required"
                      {...formik.getFieldProps("senderID")}
                      disabled={isPreviewBtn ? true : false}
                      allowClear
                      className="w-36 font-poppins"
                    />
                  )}
                </Space.Compact>
              </div>

              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">Recipients:</label>
                {isPreviewBtn ? (
                  <div>
                    {smsPreview.recipientDetails.totalRecipients} recipients
                  </div>
                ) : (
                  <Radio.Group
                    options={optionsSelector}
                    onChange={contactType}
                    value={recipientType}
                    optionType="button"
                    buttonStyle="solid"
                  />
                )}
                {/* Modal Starts Here */}
                <Modal
                  title="Upload Contact"
                  open={isModalOpen}
                  onOk={handleOk}
                  confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                  // okButtonProps={{ style: { backgroundColor: 'red' } }}
                >
                  <div>
                    <p className="text-xs p-4 justify-center">
                      This method allows you add multiple phone numbers in a
                      file
                      <br /> at once, with additional information (e.g. names,
                      email, etc).
                      <br />
                      <span className="font-semibold">
                        Phone number field is required and must have a header*
                      </span>
                    </p>
                    <div className="flex flex-col justify-between bg-white p-4 rounded-md min-h-[250px] ">
                      <p></p>

                      <div>
                        <Dragger
                          {...props}
                          className="w-72"
                          accept=".txt, .csv, .xlsx, .xls"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text text-sm">
                            Click or drag file to this area to upload
                            <br />
                            <span className="text-xs font-semibold">
                              Allowed extensions ('.txt', '.csv', '.xlsx',
                              '.xls')
                            </span>
                          </p>
                        </Dragger>
                      </div>
                    </div>
                  </div>
                </Modal>
                {/* Modal Ends Here */}
              </div>
              {isPreviewBtn ? (
                <div className="flex flex-auto gap-4">
                  <label className="w-[90px]">Amount:</label>
                  <div>
                    <p>
                      <span> ₦ </span>
                      {smsPreview.pricing.total}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="flex flex-auto gap-4">
                  <label className="w-[90px]">Destination:</label>
                  {recipientType === "typeNumbers" && (
                    <div>
                      <TextArea
  className="w-96 font-poppins"
  rows={4}
  placeholder={`Enter phone number here... e.g.,
23481234543290,
081234543290,
+23481234543290`}
  style={{
    resize: "none", 
  }}
  minLength={11}
  required="required"
  {...formik.getFieldProps("typedNumbers")}
/>
                      <p className="content" id="content"></p>
                    </div>
                  )}
                  {recipientType === "contact_group" && (
                    <div>
                      <Select
                        value={groupValue}
                        className="w-96 mb-3"
                        allowClear
                        onClear={() => setGroupValue(null)}
                        placeholder="Select Contact Group"
                        options={userGroupNames}
                        onChange={groupID}
                      />
                      <p className="content" id="content"></p>
                    </div>
                  )}
                  {uploadId && (
                    <div>
                      {/* <input
                        type="file"
                        id="file"
                        accept=".txt, .csv"
                        required="required"
                        onChange={handleFileInput}
                      ></input> */}
                      <p className="content" id="content"></p>
                      <p className=" font-semibold pt-1 text-gray-600">
                        {uploadId}
                      </p>
                    </div>
                  )}
                </div>
              )}

              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">Message:</label>
                <div className="flex-row">
                  <TextArea
                    style={{
                      height: 120,
                      resize: "none",
                    }}
                    placeholder="Compose your message here..."
                    className="w-96 font-poppins"
                    {...formik.getFieldProps("smsMessage")}
                    required="required"
                    disabled={isPreviewBtn ? true : false}
                  />
                  <p className="text-right text-gray-600 text-xs">
                    {count(formik.getFieldProps("smsMessage").value).length}/
                    {count(formik.getFieldProps("smsMessage").value).messages}
                  </p>
                </div>
              </div>

              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">Schedule:</label>
                <div className="flex flex-col">
                  <div>
                    <Switch
                      checked={flipSchedule}
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={switchSchedule}
                      disabled={isPreviewBtn ? true : false}
                      className="w-14 font-poppins bg-indigo-500"
                    />
                  </div>
                  <div className="pt-3 transition-all ease-in">
                    {flipSchedule ? (
                      <DatePicker
                        // popupClassName="bg-red-500 text-grey-400"
                        // okButtonProps={{ style: { backgroundColor: 'red' } }}
                        // okButtonProps="bg-red-500 text-gray-400"
                        showTime
                        onChange={onDateChange}
                        disabled={isPreviewBtn ? true : false}
                        allowClear
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex  flex-auto  ">
                <label className="w-[120px]"></label>
                <button
                  type="Submit"
                  className="rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-22  text-center text-decoration-none fs-8 hover:scale-105 duration-300"
                >
                  {isPreviewBtn ? "Send" : "Proceed"}
                </button>
                <label className="w-[20px]"></label>
                <button
                  className="rounded-2xl px-3 py-2 bg-red-500 text-white font-medium w-20  text-center text-decoration-none fs-8 hover:scale-105 duration-300"
                  onClick={resetForm}
                  type="button"
                >
                  Reset
                </button>
              </div>
              {/* <div className="flex flex-col flex-auto w-64">2</div> */}
            </div>
          </form>
        </Card>
      </div>
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default SendSMS;
