import { io } from "socket.io-client";
import { updateSocket } from "../store/store";

export let socket;

//socket
// const toggleSocketMode = loggedInUsers((state) => state.toggleSocketMode);

socket = io(process.env.REACT_APP_SOCKET_BASEURL, {
  reconnection: true,
  path: "/walletModule_socket",
  auth: { token: process.env.REACT_APP_SOCKET_AUTH },
});

socket.on("connect", () => {
  console.log("Socket connected");
});

socket.on("disconnect", (reason) => {
  console.log("Socket disconnected", reason);
});

socket.on("connect_error", (err) => {
  console.log("unable to connect- Reason", err.message);
});

export const initiateSocket = () => {
  //   socket.on("DashboardNotif:Wallet_Deposit", (message) => {
  //     console.log("Wallet Deposit Notification:", message);
  //   });
};

export const getSocketInstance = () => {
  initiateSocket();
  return socket;
};

export function disconnectSocket() {
  socket.diconnect();
  console.log("disconnecting socket...");
}
