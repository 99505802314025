import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  message,
  Upload,
  Modal,
  Collapse,
  theme,
  Input,
  Select,
  Checkbox,
  Form,
  Popconfirm,
  Table,
  Button,
} from "antd";
import { CaretRightOutlined, InboxOutlined } from "@ant-design/icons";

import {
  addMultipleContact,
  addSingleContact,
  allList,
  createGroup,
  deleteGroupName,
  updateGroupName,
} from "../../libs/dashboard";
import { loggedInUsers } from "../../store/store";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import headerImg from "../../../src/assets/spreadsheetHeader.svg";

const EditableContext = React.createContext(null);
var CryptoJS = require("crypto-js");
const { Panel } = Collapse;
const { Dragger } = Upload;

var userGroup;
let userGroupNames;
let tableDataSource;
// let panelactive = false;

//Table Props
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            messnor: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
        onMouseOver={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
// Table Props Ends here

const AddressGroup = () => {
  const { userdata, akpaEgo, active } = loggedInUsers((state) => state.auth);
  const [fetchGroup, setFetchGroup] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [panelactive, setPanelactive] = useState(false);
  const [headerPresent, setHeaderPresent] = useState(false);
  const [groupFId, setGroupFId] = useState("");
  const [value, setValue] = useState(null);
  const [value1, setValue1] = useState(null);
  const [dataSource, setDataSource] = useState();

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    getContactGroup();
  }, []);

  // getAllGroups
  const getContactGroup = async () => {
    const getGrp = await allList({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    // console.log("getGpr", getGrp);
    setFetchGroup(getGrp);
    userGroup = getGrp.data.data;
    userGroupNames = userGroup.map((group) => {
      return { value: group.name, label: group.name };
    });
    tableDataSource = userGroup.map((group) => {
      return {
        key: group.id,
        name: group.name,
        nor: group.totalNumberOfContacts,
      };
    });
    setDataSource(tableDataSource);
  };

  // console.log(userGroup);
  // console.log(userGroupNames);

  // Table Props Starts Here
  const handleDelete = async (key) => {
    const delGrp = await deleteGroupName({
      userId: userD?.email,
      bearerToken: userD?.bearerToken,
      groupId: key,
    });
    getContactGroup();
  };

  const defaultColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      editable: true,
    },
    {
      title: "Number of Records",
      dataIndex: "nor",
      width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: "20%",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a>Delete</a>
            </Popconfirm>{" "}
            {/* | <label onClick={EditableCell(toggleEdit)}>hi</label> */}
          </>
        ) : null,
    },
  ];

  const handleSave = async (row) => {
    const result = dataSource.find(
      (item) => item.key === row.key && item.name !== row.name
    );
    if (result) {
      const delGrp = await updateGroupName({
        userId: userD?.email,
        bearerToken: userD?.bearerToken,
        groupId: row.key,
        name: row.name,
      });
      getContactGroup();
      // console.log(result);
    }

    // const newData = [...dataSource];
    // const index = newData.findIndex((item) => row.key === item.key);
    // const item = newData[index];
    // newData.splice(index, 1, {
    //   ...item,
    //   ...row,
    // });
    // setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  // Table Props Ends Here

  const groupID = (value) => {
    // console.log("groupID", value);
    if (value) {
      let gId = userGroup.find((item) => item.name === value);
      formik.setFieldValue("groupId", gId.id);
      setValue(value);
      // console.log(gId.id);
    } else {
      formik.setFieldValue("groupId");
      // console.log("empty");
    }
  };

  const fileGroupID = (value) => {
    if (value) {
      let gId = userGroup.find((item) => item.name === value);
      setGroupFId(gId.id);
      // console.log(gId.id);
      setValue1(value);
      // console.log(groupFId);
    } else {
      setGroupFId("");
    }
  };

  const handleCUpload = async (uploadedFileName) => {
    const addCupload = await addMultipleContact({
      userId: userD?.email,
      bearerToken: userD?.bearerToken,
      groupId: groupFId,
      uploadedFileName,
    });
    const { data } = addCupload;
    toast.success(data.message);
    getContactGroup();
    setValue1(null);
  };

  const onChbk = (e) => {
    // console.log(`checked = ${e.target.checked}`);
    setHeaderPresent(e.target.checked);
  };

  //   const getContactGroup = async () => {
  //     const getGrp = await getAllGroups({
  //       email: userD?.email,
  //       bearerToken: userD?.bearerToken,
  //     });
  //     // const { data, status } = getGrp;
  //     // console.log(data);
  //     // console.log(status);
  //     if (getGrp.ok) {
  //       // console.log(getGrp.data);
  //       console.log("if ok", getGrp.data);
  //       return getGrp.data;
  //     } else {
  //       console.log(getGrp.error);
  //       return getGrp.error;
  //     }
  //     // setSmsM(`${data.data.totalCount}`);
  //   };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // dropdown panel
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 20,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
    // background: "#d36128",
  };
  // end here

  // Upload File starts here
  const headers = { authorization: `Bearer ${userD?.bearerToken}` };
  const props = {
    name: "file",
    multiple: false,
    action: `${process.env.REACT_APP_SMSMODULE_BASEURL}/uploads/uploadFileForContactGroup?headerPresent=${headerPresent}&userId=${userD?.email}`,
    headers,
    onChange(info) {
      const { status, response } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        console.log(info.file.name);
        const uploadedFileName = response.data.uploadFileName;
        // console.log("uploadedFileName", uploadedFileName);
        setTimeout(() => {
          handleCUpload(uploadedFileName);
        }, 500);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setValue(null);
        // console.log("response", response);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // End of upload

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      groupId: "",
      groupFId: "",
    },
    validate: "",
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = Object.assign(
        values,
        { userId: userD?.email || "" },
        { bearerToken: userD?.bearerToken || "" }
      );
      if (values.groupId) {
        // console.log("withID", values);
        const addNum = await addSingleContact(values);
        const {
          data: { data },
        } = addNum;
        const msg = `${values.phoneNumber} has been added successfully`;
        toast.success(msg);
        getContactGroup();
        setValue(null);
        formik.resetForm();
      } else {
        console.log(values);
        // return;
        try {
          const smsResp = await createGroup(values);
          const {
            data: { data },
          } = smsResp;
          toast.success("Contact Group Created Successfully.");
        } catch (error) {
          toast.error(error.response.data.message);
          console.error("Error Creating Group");
        }
        getContactGroup();
        formik.resetForm();
      }
    },
  });

  return (
    <div>
      <h1 className="text-lg font-semibold p-1">Address Book</h1>
      <div className="flex flex-col sm:flex-row">
        <div className="p-2 grow w-2/3 ss:w-[100%] sm:w-2/3">
          <Button
            onClick={showModal}
            type="primary"
            style={{
              marginBottom: 10,
            }}
          >
            Create New Group
          </Button>
          <Table
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={dataSource}
            columns={columns}
          />
        </div>

        <Modal
          title="Address Group"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={410}
          bodyStyle={{ height: panelactive ? 500 : 350 }}
          height={600}
          style={{ top: 30 }}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Collapse
            className="pt-6"
            bordered={false}
            // defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              (<CaretRightOutlined rotate={isActive ? 90 : 0} />),
              //   console.log("inside", isActive)
              setPanelactive(isActive)
            )}
            style={{
              background: token.colorBgContainer,
            }}
          >
            <Panel header="Create Contact Group" key="1" style={panelStyle}>
              <div className="p-2 pb-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="">
                    <Input
                      placeholder="Group Name"
                      required="required"
                      value={formik.getFieldProps("name")}
                      {...formik.getFieldProps("name")}
                      //   disabled={isPreviewBtn ? true : false}
                      allowClear
                      className="w-56 font-poppins"
                    />
                    <button
                      type="Submit"
                      //   disabled={uploadId ? false : true}
                      className={`rounded-lg px-2 py-2 ml-4 bg-blue-500 text-decoration-none  hover:bg-blue-700 text-white font-medium text-sm  text-center `}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </Panel>
            <Panel header="Upload Single Contact" key="2" style={panelStyle}>
              <div className="p-2 pb-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="">
                    <Select
                      value={value}
                      className="w-[90%] mb-3"
                      allowClear
                      onClear={() => setValue(null)}
                      placeholder="Select Contact Group"
                      options={userGroupNames}
                      onChange={groupID}
                    />
                    <Input
                      placeholder="Add Single Number"
                      required="required"
                      // value={formik.getFieldProps("phoneNumber")}
                      {...formik.getFieldProps("phoneNumber")}
                      //   disabled={isPreviewBtn ? true : false}
                      allowClear
                      className="w-56 font-poppins"
                    />
                    <button
                      type="Submit"
                      //   disabled={uploadId ? false : true}
                      className={`rounded-lg px-2 py-2 ml-4 bg-blue-500 text-decoration-none  hover:bg-blue-700 text-white font-medium text-sm  text-center `}
                    >
                      Add
                    </button>
                    {/* <p className="text-xs text-gray-500">2348000000022</p> */}
                  </div>
                </form>
              </div>
            </Panel>
            <Panel header="Upload Multiple Contact" key="3" style={panelStyle}>
              <div>
                <Select
                  value={value1}
                  className="w-[100%] mb-1"
                  allowClear
                  onClear={() => setValue1(null)}
                  placeholder="Select Contact Group"
                  options={userGroupNames}
                  onChange={fileGroupID}
                />
                <Checkbox onChange={onChbk} className="text-sm p-1 mb-1">
                  Upload file with header?
                </Checkbox>
                <img src={headerImg} alt="HeaderImg" />
                <Dragger
                  {...props}
                  className="w-72"
                  accept=".txt, .csv, .xlsx, .xls"
                >
                  <p className="ant-upload-drag-icon h-7">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text text-xs">
                    <span className="text-sm">
                      Click or drag file to this area to upload
                    </span>
                    <br />
                    <span className="text-xs font-semibold">
                      Allowed extensions ('.txt', '.csv', '.xlsx', '.xls')
                    </span>
                  </p>
                </Dragger>
                <p>&nbsp;</p>
              </div>
            </Panel>
          </Collapse>
        </Modal>
      </div>
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default AddressGroup;
