import React from "react";

const CustomInput = (props) => {
  const { type, label, id, i_class, required, onChange } = props;
  return (
    <div className="form-floating mb-3">
      <input
        type={type}
        className={`form-control ${i_class} rounded-xl`}
        name={id}
        id={id}
        placeholder={label}
        required={required}
        onChange={onChange}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default CustomInput;
