import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";

export async function getWalletBalance({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_WALLETMODULE_BASEURL}/wallet/getWalletBalance?userId=${email}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting Wallet");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function fundWallet(credentials) {
  try {
    const payload = {
      userId: credentials.userId,
      amount: credentials.amount,
      method: credentials.method,
    };
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_WALLETMODULE_BASEURL}/payment/initiateNewPayment`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Fundind Wallet.");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function previewSMS(credentials) {
  var recipients;
  let payload;
  try {
    if (credentials.recipientType === "typeNumbers") {
      recipients = numbaSplit(credentials.typedNumbers);
      payload = {
        senderId: credentials.senderID,
        userId: credentials.userId,
        messageType: credentials.messageType,
        message: credentials.smsMessage,
        recipientInputMode: "manual_input",
        recipients: recipients,
        isScheduled: credentials.isScheduled,
        scheduleDateTime: credentials.scheduleDateTime,
      };
    } else if (credentials.recipientType === "upload") {
      recipients = credentials.file;
      payload = {
        senderId: credentials.senderID,
        userId: credentials.userId,
        messageType: credentials.messageType,
        message: credentials.smsMessage,
        recipientInputMode: "file_upload",
        // fileUploaded: credentials.fileUploaded,
        uploadedFileName: credentials.uploadedFileName,
        isScheduled: credentials.isScheduled,
        scheduleDateTime: credentials.scheduleDateTime,
      };
    } else if (credentials.recipientType === "contact_group") {
      recipients = credentials.file;
      payload = {
        senderId: credentials.senderID,
        userId: credentials.userId,
        messageType: credentials.messageType,
        message: credentials.smsMessage,
        recipientInputMode: "contact_group",
        groupId: credentials.groupId,
        isScheduled: credentials.isScheduled,
        scheduleDateTime: credentials.scheduleDateTime,
      };
    }
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/sms/preview/singleMessage`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Sending SMS.");
    if (error.response) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
    console.error(error.response.data.message);
  }
}

export async function sendSMS(credentials) {
  var recipients;
  let payload;
  try {
    if (credentials.recipientType === "typeNumbers") {
      recipients = numbaSplit(credentials.typedNumbers);
      payload = {
        senderId: credentials.senderID,
        userId: credentials.userId,
        messageType: credentials.messageType,
        message: credentials.smsMessage,
        recipientInputMode: "manual_input",
        recipients: recipients,
        isScheduled: credentials.isScheduled,
        scheduleDateTime: credentials.scheduleDateTime,
      };
    } else if (credentials.recipientType === "upload") {
      recipients = credentials.file;
      payload = {
        senderId: credentials.senderID,
        userId: credentials.userId,
        messageType: credentials.messageType,
        message: credentials.smsMessage,
        recipientInputMode: "file_upload",
        // fileUploaded: credentials.fileUploaded,
        isScheduled: credentials.isScheduled,
        scheduleDateTime: credentials.scheduleDateTime,
        uploadedFileName: credentials.uploadedFileName,
      };
    } else if (credentials.recipientType === "contact_group") {
      recipients = credentials.file;
      payload = {
        senderId: credentials.senderID,
        userId: credentials.userId,
        messageType: credentials.messageType,
        message: credentials.smsMessage,
        recipientInputMode: "contact_group",
        groupId: credentials.groupId,
        isScheduled: credentials.isScheduled,
        scheduleDateTime: credentials.scheduleDateTime,
      };
    }
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/sms/send/singleMessage`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Sending SMS.");
    if (error.response) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
    console.error(error.response.data.message);
  }
}

export async function previewSMSPersonalized(credentials) {
  let payload;
  try {
    payload = {
      senderId: credentials.sender_ID,
      userId: credentials.userId,
      messageType: credentials.messageType,
      messageTemplate: credentials.smsMessage,
      recipientInputMode: "file_upload",
      uploadedFileName: credentials.uploadedFileName,
      isScheduled: credentials.isScheduled,
      scheduleDateTime: credentials.scheduleDateTime,
    };
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/sms/preview/personalizedMessage`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Sending SMS.");
    if (error.response) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function sendSMSPersonalized(credentials) {
  let payload;
  try {
    payload = {
      senderId: credentials.sender_ID,
      userId: credentials.userId,
      messageType: credentials.messageType,
      messageTemplate: credentials.smsMessage,
      recipientInputMode: "file_upload",
      uploadedFileName: credentials.uploadedFileName,
      isScheduled: credentials.isScheduled,
      scheduleDateTime: credentials.scheduleDateTime,
    };
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/sms/send/personalizedMessage`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Sending SMS.");
    if (error.response) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function getAllSenderId({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/senderId/getAllSenderIds?userId=${email}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting SenderIdTR");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function getSMSUser({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/user/getUser?userId=${email}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting SMS User");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function sMsDay({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/totalSmsSentForTheDay?userId=${email}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting SMS Day Count");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function sMsMonth({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/totalSmsSentForTheMonth?userId=${email}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting SMS Month Count");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function getAllGroups({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/contactGroup/getAllGroups?userId=${email}`,
      { headers }
    );
    return {
      ok: true,
      data: callResp,
    };
  } catch (error) {
    console.error("Error Getting Contact Groups");
    if (error.response) {
      console.error(error.response.data.message);
      // toast.error(error.response.data.message);
      if (error.response.status === 404) {
        return {
          ok: false,
          error: "No contact group exist for this user",
        };
      } else {
        return {
          ok: false,
          error: "We ran into an issue, try again later",
        };
      }
    } else {
      return {
        ok: false,
        error: "We ran into an issue, try again later",
      };
    }
    console.error(error);
    // throw error;
  }
}

export async function addSingleContact(credentials) {
  var recipients;
  let payload;
  try {
    payload = {
      userId: credentials.userId,
      groupId: credentials.groupId,
      phoneNumber: credentials.phoneNumber,
    };

    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/contactGroup/addSingleContact`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Sending SMS.");
    if (error.response) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function addMultipleContact(credentials) {
  var recipients;
  let payload;
  try {
    payload = {
      userId: credentials.userId,
      groupId: credentials.groupId,
      uploadedFileName: credentials.uploadedFileName,
    };
    // console.log(payload);
    // return;
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/contactGroup/addMultipleContacts`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Sending SMS.");
    if (error.response) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function createGroup(credentials) {
  try {
    const payload = {
      userId: credentials.userId,
      name: credentials.name,
    };
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.post(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/contactGroup/createGroup`,
      payload,
      { headers }
    );

    return callResp;
  } catch (error) {
    console.error("Error Creating Group.");
    if (error.response) {
      console.error(error.response.data.message);
      // toast.error(error.response.data.message);
    }
    console.error(error);
    throw error;
  }
}

export async function updateGroupName(credentials) {
  try {
    const payload = {
      userId: credentials.userId,
      groupId: credentials.groupId,
      name: credentials.name,
    };
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };
    const callResp = await axios.put(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/contactGroup/updateGroupName`,
      payload,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Creating Group.");
    if (error.response) {
      console.error(error.response.data.message);
      // toast.error(error.response.data.message);
    }
    console.error(error);
    throw error;
  }
}

export async function deleteGroupName(credentials) {
  try {
    const payload = {
      userId: credentials.userId,
      groupId: credentials.groupId,
    };
    const headers = { authorization: `Bearer ${credentials.bearerToken}` };

    const callResp = await axios.delete(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/contactGroup/deleteGroupName`,
      {
        headers,
        data: payload,
      }
    );

    return callResp;
  } catch (error) {
    console.error("Error Creating Group.");
    if (error.response) {
      console.error(error.response.data.message);
      // toast.error(error.response.data.message);
    }
    console.error(error);
    throw error;
  }
}

export async function smsTransactionDetailN11({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = {
      "x-pagination-limit": "10",
      "x-pagination-page": "1",
      authorization: `Bearer ${bearerToken}`,
    };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/smsTransactionDetail?userId=${email}`,
      { headers }
    );
    // console.log("header", callResp.headers);
    // console.log("header1", callResp.headers["content-length"]);
    // console.log("header2", callResp.headers["x-pagination-totalpages"]);
    // console.log("header3", callResp.headers["x-pagination-totalcount"]);
    return callResp;
  } catch (error) {
    console.error("Error Getting Transaction Details");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function smsTransactionDetail(credentials) {
  // console.log(credentials);
  let search;
  try {
    const payload = { email: credentials.email };
    // const filter =
    if (credentials.filteredItem) {
      // search = `filtered item is.${credentials.searchedItem}`;
      search = `&${credentials.filteredItem}=${credentials.searchedItem}`;
    } else if (credentials.SD) {
      search = `&startDate=${credentials.SD}&endDate=${credentials.ED}`;
    } else {
      search = "";
    }
    const headers = {
      "x-pagination-limit": credentials.perPage,
      "x-pagination-page": credentials.page,
      authorization: `Bearer ${credentials.bearerToken}`,
    };
    // console.log("headers", headers);
    // console.log("search", search);
    // return;
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/smsTransactionDetail?userId=${credentials.email}${search}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting Transaction Details");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function smsTransactions(credentials) {
  let search;
  try {
    const payload = { email: credentials.email };
    if (credentials.filteredItem) {
      // search = `filtered item is.${credentials.searchedItem}`;
      search = `&${credentials.filteredItem}=${credentials.searchedItem}`;
    } else if (credentials.SD) {
      search = `&startDate=${credentials.SD}&endDate=${credentials.ED}`;
    } else {
      search = "";
    }
    const headers = {
      "x-pagination-limit": credentials.perPage,
      "x-pagination-page": credentials.page,
      authorization: `Bearer ${credentials.bearerToken}`,
    };
    // console.log("search", search);
    // console.log("log", credentials);
    // return;
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/smsTransactions?userId=${credentials.email}${search}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting Transaction Report");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function scheduledJobs(credentials) {
  try {
    // const payload = { email: credentials.email };
    let search;
    if (credentials.filteredItem) {
      // search = `filtered item is.${credentials.searchedItem}`;
      search = `&${credentials.filteredItem}=${credentials.searchedItem}`;
    } else if (credentials.SD) {
      search = `&startDate=${credentials.SD}&endDate=${credentials.ED}`;
    } else {
      search = "";
    }
    const headers = {
      "x-pagination-limit": credentials.perPage,
      "x-pagination-page": credentials.page,
      authorization: `Bearer ${credentials.bearerToken}`,
    };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/scheduledJobs?userId=${credentials.email}${search}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting scheduled Jobs Report");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function walletTransactions(credentials) {
  try {
    // const payload = { email: credentials.email };
    let search;
    if (credentials.filteredItem) {
      // search = `filtered item is.${credentials.searchedItem}`;
      search = `&${credentials.filteredItem}=${credentials.searchedItem}`;
    } else if (credentials.SD) {
      search = `&startDate=${credentials.SD}&endDate=${credentials.ED}`;
    } else {
      search = "";
    }
    const headers = {
      "x-pagination-limit": credentials.perPage,
      "x-pagination-page": credentials.page,
      authorization: `Bearer ${credentials.bearerToken}`,
    };
    const callResp = await axios.get(
      `${process.env.REACT_APP_WALLETMODULE_BASEURL}/reports/walletTransactions?userId=${credentials.email}${search}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting scheduled Jobs Report");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

export async function paymentRequests(credentials) {
  try {
    // const payload = { email: credentials.email };
    let search;
    if (credentials.filteredItem) {
      // search = `filtered item is.${credentials.searchedItem}`;
      search = `&${credentials.filteredItem}=${credentials.searchedItem}`;
    } else if (credentials.SD) {
      search = `&startDate=${credentials.SD}&endDate=${credentials.ED}`;
    } else {
      search = "";
    }
    const headers = {
      "x-pagination-limit": credentials.perPage,
      "x-pagination-page": credentials.page,
      authorization: `Bearer ${credentials.bearerToken}`,
    };
    const callResp = await axios.get(
      `${process.env.REACT_APP_WALLETMODULE_BASEURL}/reports/paymentRequests?userId=${credentials.email}${search}`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting scheduled Jobs Report");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}

function numbaSplit(convertrecp) {
  // const myString = e.target.value;
  const splited = convertrecp?.split(",\n");
  if (splited[splited.length - 1] == "") splited.pop();
  return splited;
}

export async function allList({ email, bearerToken }) {
  const getGrp = await getAllGroups({
    email,
    bearerToken,
  });
  if (getGrp.ok) {
    // console.log("if ok", getGrp.data);
    return getGrp.data;
  } else {
    // console.log(getGrp.error);
    return getGrp.error;
  }
}

export async function getAllNetworks({ email, bearerToken }) {
  try {
    const payload = { email };
    const headers = { authorization: `Bearer ${bearerToken}` };
    const callResp = await axios.get(
      `${process.env.REACT_APP_SMSMODULE_BASEURL}/reports/getAllNetworks`,
      { headers }
    );
    return callResp;
  } catch (error) {
    console.error("Error Getting getAllNetworks");
    if (error.response) {
      console.error(error.response.data);
    }
    console.error(error);
  }
}
