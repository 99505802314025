import React, { useEffect, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { AiOutlineDashboard } from "react-icons/ai";
import { IoIosNotifications, IoIosCreate } from "react-icons/io";
import { MdOutlinePermPhoneMsg, MdViewList, MdMessage } from "react-icons/md";
import { BiMessageEdit, BiWalletAlt, BiPowerOff } from "react-icons/bi";
import { FaSms, FaAddressBook, FaHistory } from "react-icons/fa";
import { SiGooglemessages } from "react-icons/si";
import { FiSettings } from "react-icons/fi";
import { TbReport, TbReportAnalytics, TbFileReport } from "react-icons/tb";
import { useNavigate, Outlet } from "react-router-dom";
import { loggedInUsers } from "../store/store";
import { getWalletBalance } from "../libs/dashboard";
import { BsWalletFill } from "react-icons/bs";
import { formatNumberAsCurrency } from "../libs/decrypt";
import profilePic from "../../src/assets/profile.png";

const { Header, Sider, Content } = Layout;
var CryptoJS = require("crypto-js");

const MainLayout = () => {
  // Fetch data from store.
  const { userdata, akpaEgo } = loggedInUsers((state) => state.auth);
  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));
  //getWallet
  var getWallet = CryptoJS.AES.decrypt(
    akpaEgo,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var wallet = JSON.parse(getWallet.toString(CryptoJS.enc.Utf8));

  const bal = formatNumberAsCurrency(wallet?.balance);

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <h2 className="text-2xl text-center py-3 mb-0 font-semibold text-black">
            <span className="sm-logo">BBC</span>
            <span className="lg-logo">BroadBased</span>
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key === "signout") {
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "../dashboard",
              icon: <AiOutlineDashboard className="fs-4" />,
              label: "Dashboard",
              // children: [
              //   {
              //     key: "../dashboard",
              //     icon: <AiOutlineDashboard className="fs-5" />,
              //     label: "Dashboard",
              //   },
              // ],
            },
            {
              key: "sms",
              icon: <FaSms className="fs-4" />,
              label: "SMS",
              children: [
                {
                  key: "send-sms",
                  icon: <MdMessage className="fs-5" />,
                  label: "Send SMS",
                },
                {
                  key: "personalised-sms",
                  icon: <SiGooglemessages className="fs-5" />,
                  label: "Personalized Messages",
                },
              ],
            },
            {
              key: "addressbook",
              icon: <FaAddressBook className="fs-4" />,
              label: "Address Book",
              children: [
                {
                  key: "address-book",
                  icon: <IoIosCreate className="fs-5" />,
                  label: "Create Group",
                },
                {
                  key: "#view-address",
                  icon: <MdViewList className="fs-5" />,
                  label: "View List",
                },
              ],
            },
            {
              key: "#",
              icon: <TbReport className="fs-4" />,
              label: "Report",
              children: [
                {
                  key: "sms-history",
                  icon: <FaHistory className="fs-5" />,
                  label: "SMS Details",
                },
                {
                  key: "sms-report",
                  icon: <TbReportAnalytics className="fs-5" />,
                  label: "SMS Summary",
                },
                {
                  key: "scheduled-report",
                  icon: <TbFileReport className="fs-5" />,
                  label: "Scheduled Report",
                },
                {
                  key: "wallet-transactions",
                  icon: <BiWalletAlt className="fs-5" />,
                  label: "Wallet History",
                },
                {
                  key: "payment-history",
                  icon: <BiWalletAlt className="fs-5" />,
                  label: "Payment History",
                },
              ],
            },
            {
              key: "#ussd",
              icon: <MdOutlinePermPhoneMsg className="fs-4" />,
              label: "USSD",
            },
            {
              key: "sender-Id",
              icon: <BiMessageEdit className="fs-4" />,
              label: "Sender ID",
            },
            {
              key: "my-wallet",
              icon: <BiWalletAlt className="fs-4" />,
              label: "My Wallet",
              children: [
                {
                  key: "wallet",
                  icon: <BiWalletAlt className="fs-4" />,
                  label: "Fund Wallet",
                },

                // {
                //   key: "wallet-topUp",
                //   icon: <FaSms />,
                //   label: "Wallet Top Up",
                // },
              ],
            },
            {
              key: "settings",
              icon: <FiSettings className="fs-4" />,
              label: "Settings",
            },
            {
              key: "../auth/logout",
              icon: <BiPowerOff className="fs-4" />,
              label: "Logout",
            },
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          className="d-flex justify-between ps-1 pe-5 "
          style={{ padding: 0, background: colorBgContainer }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}

          <div className="d-flex gap-4 items-center">
            <div className="flex items-center">
              <h6 className="text-gray-900 mr-1 text-xl">
                <BsWalletFill />
              </h6>
              <p className="font-semibold text-sm">
                {wallet?.currency} {bal}
              </p>
            </div>
            <div className="relative">
              <IoIosNotifications className="fs-4" />
              <span className="badge bg-warning rounded-xl p-1 absolute">
                0
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <div>
                <img
                  className="object-cover h-9 w-9 rounded-lg"
                  src={profilePic}
                  alt=""
                ></img>
              </div>
              <div>
                <h5 className="leading-[20px] font-semibold text-gray-700 text-[14px] mb-0">
                  {`${userD.firstName} ${userD.lastName}`}
                </h5>
                <p className="leading-[10px] text-[11px] mb-0">{userD.email}</p>
              </div>
            </div>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
