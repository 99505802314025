var cryptojs = require("crypto-js");

export function socketMessageDecrypt(encryptedInfo) {
  let key = cryptojs.enc.Hex.parse(process.env.REACT_APP_SOCKET_ENCRYPTION_KEY);
  let iv = cryptojs.enc.Hex.parse(encryptedInfo.iv);
  let ciphertext = cryptojs.enc.Hex.parse(encryptedInfo.encryptedMessage);

  let encryptedCP = cryptojs.lib.CipherParams.create({
    ciphertext,
    formatter: cryptojs.format.OpenSSL,
  });

  let decryptedWA = cryptojs.AES.decrypt(encryptedCP, key, {
    iv,
  });

  let decryptedFinal = decryptedWA.toString(cryptojs.enc.Utf8);

  return JSON.parse(decryptedFinal);
}

export function formatNumberAsCurrency(num) {
  if (typeof num === "string") {
    num = Number(num);
    // console.log(num);
  }
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function encryptData(encryptedInfo) {
  // Encrypt
  var encryptData = cryptojs.AES.encrypt(
    JSON.stringify(encryptedInfo),
    process.env.REACT_APP_SOCKET_ENCRYPT_KEY
  ).toString();
  return encryptData;
}

export function decryptData(decryptedInfo) {
  // Decrypt
  var decryptVar = cryptojs.AES.decrypt(
    decryptedInfo,
    process.env.REACT_APP_SOCKET_ENCRYPT_KEY
  );
  var decryptData = JSON.parse(decryptVar.toString(cryptojs.enc.Utf8));

  return decryptData;
}
