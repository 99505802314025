import React from 'react'
import CustomInput from './CustomInput'

const ResetPassword = () => {
  return (
    <div className="bg-primaryC py-5 min-h-screen font-roboto">
      <div className="my-5 w-25 bg-white rounded-3xl mx-auto p-4">
        <h3 className='text-center text-2xl mb-2'>Reset Password</h3>
        <p className='text-center mb-4'>Please enter your new password</p>
        <form action="">
        <CustomInput type="password" label='New Password' id='pass' />
        <CustomInput type="password" label='Confirm Password' id='confirmpass' />
        <button className='border-0 px-3 py-2 bg-primaryC text-gray-900 font-medium w-100' type='submit'>Reset Password</button>
        </form>
      </div>
    </div>
  )
}


export default ResetPassword