import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "./CustomInput";
import { authenticateUser, registerNewUser } from "../../libs";
import { loggedInUsers } from "../../store/store";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import styles from "./index.css";
import logo from "../../assets/Logo.png";
import { getWalletBalance } from "../../libs/dashboard";
import { validateLogin, registerValidation } from "../../libs/helper";
import Cookies from "universal-cookie";

const initialState = {};

const LoginNew = () => {
  var CryptoJS = require("crypto-js");

  // Cookie Test
  const cookie = new Cookies();

  // const [form, setForm] = useState(initialState);
  const [isSignup, setIsSignup] = useState(false);
  const [error, setError] = useState("");
  const setActiveUser = loggedInUsers((state) => state.setActiveUser);
  const toggleActiveMode = loggedInUsers((state) => state.toggleActiveMode);
  const setAkpaEgo = loggedInUsers((state) => state.setAkpaEgo);
  //socket
  const toggleSocketMode = loggedInUsers((state) => state.toggleSocketMode);
  const { socket } = loggedInUsers((state) => state.auth);

  const navigate = useNavigate();

  const valid8 = isSignup ? registerValidation : validateLogin;

  const logSocket = () => {
    toggleSocketMode("connected");
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      address: "",
      password: "",
      confirmPass: "",
    },
    validate: valid8,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!isSignup) {
        try {
          if (values.email && values.password) {
            const authUser = await authenticateUser({
              email: values.email,
              password: values.password,
            });
            const {
              data: {
                data: { userVerified, bearerToken },
              },
            } = authUser;
            // console.log(authUser.data.data);
            toast.success(authUser.data.message);
            console.log(authUser.data.message);

            // Setting loggedin user to cookie to get notification.
            if (!cookie.get(values.email)) {
              cookie.set(values.email, [""]);
            }

            // Encrypt
            var encryptUser = CryptoJS.AES.encrypt(
              JSON.stringify(authUser.data.data),
              process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
            ).toString();

            setActiveUser(encryptUser);
            toggleActiveMode(true);

            //Fetching Wallet
            if (bearerToken) {
              const getWallet = await getWalletBalance({
                email: values.email,
                bearerToken,
              });
              const { data } = getWallet;

              var walletEncry = CryptoJS.AES.encrypt(
                JSON.stringify(data.data),
                process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
              ).toString();
              setAkpaEgo(walletEncry);
            }
            setTimeout(() => {
              if (userVerified === false) {
                navigate("../auth/otp");
              } else {
                navigate("/dashboard");
              }
            }, 1000);
          }
        } catch (error) {
          console.error("Error Authenticating User Log");
        }
      } else if (isSignup) {
        try {
          const regNewUser = await registerNewUser(values);
          const {
            data: { data, message },
          } = regNewUser;
          console.log(data);
          console.log(message);
          toast.success(message);
          formik.resetForm();
          setTimeout(() => {
            console.log("register Successful");
            // navigate("/auth/otp");
            switchMode();
            toast.success("Login to input OTP");
          }, 1000);
        } catch (error) {
          console.error("Error registering user");
        }
      }
    },
  });

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
  };

  return (
    <div className="logpage bg-primaryC py-4 min-h-screen">
      <div
        className={`glass my-3 ${
          isSignup && "md:w-[500px] sm:w-[450px]"
        }  w-[350px] mx-auto `}
      >
        <div className="flex justify-center mb-2">
          <img
            src={logo}
            className="border-4 border-gray-100 w-[120px] rounded-md shadow-lg "
            alt="avatar"
          />
        </div>
        <h3 className="text-center text-3xl mb-2">
          {isSignup ? "Sign Up" : "Sign In New"}
        </h3>
        <p className="text-center mb-4 xs:text-[15px]  text-[12px]">
          {isSignup ? "" : "Login to your account to continue"}
        </p>
        <form onSubmit={formik.handleSubmit}>
          {isSignup && (
            <div>
              <CustomInput
                type="text"
                {...formik.getFieldProps("firstName")}
                label="First Name"
                id="firstName"
                required="required"
              />
              <CustomInput
                type="text"
                {...formik.getFieldProps("lastName")}
                label="Last Name"
                id="lastName"
                required="required"
              />
            </div>
          )}
          <CustomInput
            type="text"
            {...formik.getFieldProps("email")}
            label="Email Address"
            id="email"
            required="required"
          />
          {isSignup && (
            <div>
              <CustomInput
                type="text"
                {...formik.getFieldProps("phoneNumber")}
                label="Phone Number"
                id="phoneNumber"
                required="required"
              />
              <CustomInput
                type="text"
                {...formik.getFieldProps("address")}
                label="Address"
                id="address"
                required="required"
              />
              {/* <input type="hidden" id="address" value="Default" onChange={handleChange}></input> */}
            </div>
          )}
          <CustomInput
            type="password"
            {...formik.getFieldProps("password")}
            label="Password"
            id="password"
            required="required"
          />
          {isSignup && (
            <div>
              <CustomInput
                type="password"
                {...formik.getFieldProps("confirmPass")}
                label="Confirm Password"
                id="confirmPass"
                required="required"
              />
            </div>
          )}
          <div className="mb-3 text-right text-xs">
            {!isSignup && (
              <Link to="/forgot-password" className="font-bold">
                Forgot Password?
              </Link>
            )}
          </div>
          <div className="justify-center text-center mb-2">
            <span className="text-red-700 font-bold">{error}</span>
          </div>
          <button
            className="rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-100 text-center text-decoration-none fs-5 hover:scale-105 duration-300"
            type="submit"
          >
            {isSignup ? "Sign Up" : "Sign In"}
          </button>
          <div className="text-xs mt-3">
            <p>
              {isSignup ? "Already have an account?" : "Dont have an account?"}
              <span onClick={switchMode} className="font-bold cursor-pointer">
                {" "}
                {isSignup ? "Sign In" : "Sign Up"}
              </span>
            </p>
          </div>
        </form>
      </div>
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default LoginNew;
