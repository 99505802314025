import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Form, Input, Popconfirm, Table } from "antd";
import { loggedInUsers } from "../../store/store";
import {
  allList,
  deleteGroupName,
  updateGroupName,
} from "../../libs/dashboard";
const EditableContext = React.createContext(null);

var CryptoJS = require("crypto-js");
var userGroup;
let userGroupNames;

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            messnor: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
        onMouseOver={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const ViewList = () => {
  const [dataSource, setDataSource] = useState();
  const { userdata } = loggedInUsers((state) => state.auth);
  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );

  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    getContactGroup();
  }, []);

  // getAllGroups
  const getContactGroup = async () => {
    const getGrp = await allList({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    userGroup = getGrp.data.data;
    userGroupNames = userGroup.map((group) => {
      return {
        key: group.id,
        name: group.name,
        nor: group.totalNumberOfContacts,
      };
    });
    setDataSource(userGroupNames);
    // return userGroupNames;
  };

  //   const [dataSource, setDataSource] = useState(userGroupNames);
  //   const [dataSource, setDataSource] = useState([
  //     {
  //       key: "0",
  //       name: "Edward King 0",
  //       nor: "32",
  //     },
  //     {
  //       key: "1",
  //       name: "Edward King 1",
  //       nor: "32",
  //     },
  //   ]);

  const [count, setCount] = useState(2);

  const handleDelete = async (key) => {
    const delGrp = await deleteGroupName({
      userId: userD?.email,
      bearerToken: userD?.bearerToken,
      groupId: key,
    });
    getContactGroup();
  };

  const defaultColumns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "30%",
      editable: true,
    },
    {
      title: "Number of Records",
      dataIndex: "nor",
      width: "20%",
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: "20%",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <a>Delete</a>
            </Popconfirm>{" "}
            {/* | <label onClick={EditableCell(toggleEdit)}>hi</label> */}
          </>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: `Edward King ${count}`,
      nor: "32",
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = async (row) => {
    const result = dataSource.find(
      (item) => item.key === row.key && item.name !== row.name
    );
    if (result) {
      const delGrp = await updateGroupName({
        userId: userD?.email,
        bearerToken: userD?.bearerToken,
        groupId: row.key,
        name: row.name,
      });
      getContactGroup();
      // console.log(result);
    }

    // const newData = [...dataSource];
    // const index = newData.findIndex((item) => row.key === item.key);
    // const item = newData[index];
    // newData.splice(index, 1, {
    //   ...item,
    //   ...row,
    // });
    // setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        Add a row
      </Button>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};
export default ViewList;
