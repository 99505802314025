import React, { useEffect, useState } from "react";
// import '../App.css';
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "./CustomInput";
import { loggedInUsers } from "../../store/store";
import { verificationCode, resendOTP } from "../../libs";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./index.css";

var CryptoJS = require("crypto-js");

const initialState = {
  otp: "",
};

const OTP = () => {
  //   const [opt, setOTP] = useState(false)
  const [form, setForm] = useState();

  // Fetch data from store.
  const { userdata } = loggedInUsers((state) => state.auth);
  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  // const cookie = new Cookies();
  const { email } = userD;
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!userD?.email) navigate("/login");
  //   if (userD?.userVerified === true) navigate("/dashboard");
  // });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const resendVerificationCode = async () => {
    //const otp = form.otp;
    try {
      const resend = await resendOTP({ email });
      const {
        data: { data, message },
      } = resend;
      console.log(message);
      toast.success(resend.data.message);
      console.log("Verification code sent");
    } catch (error) {}
    console.log("reset");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const otp = form.otp;
    try {
      if (otp) {
        const verifyOTP = await verificationCode({
          email,
          verificationCode: otp,
        });
        const {
          data: { data, message },
        } = verifyOTP;
        console.log(message);
        toast.success(verifyOTP.data.message);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      toast.error("Enter a valid OTP");
      return error;
    }
  };

  return (
    <div className="logpage bg-primaryC py-4 min-h-screen">
      <div className={`glass my-5 w-[320px] mx-auto `}>
        <h3 className="text-center text-3xl mb-2">Complete Registration</h3>
        <p className="text-center mb-4 xs:text-[15px]  text-[12px]">
          Enter OTP sent to {userD?.email} to verify email
        </p>
        <form onSubmit={handleSubmit}>
          <CustomInput
            type="text"
            label="Enter OTP"
            id="otp"
            required="required"
            onChange={handleChange}
          />

          <div className="mb-3 text-right text-xs">
            {/* {!isSignup && <Link to='/forgot-password' className='font-bold'>Login</Link>} */}
          </div>
          <button
            className="rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-100 text-center text-decoration-none fs-5"
            type="submit"
          >
            Verify
          </button>
          <div className="text-xs mt-3">
            <p>
              Didn't receive OTP?
              <span
                onClick={resendVerificationCode}
                className="font-bold cursor-pointer"
              >
                {" "}
                Resend
              </span>
            </p>
          </div>
        </form>
      </div>
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default OTP;
