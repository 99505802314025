import React, { useEffect, useState } from "react";
import {
  Card,
  Select,
  Input,
  Button,
  Modal,
  message,
  DatePicker,
  Upload,
  Switch,
} from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import {
  getAllSenderId,
  getSMSUser,
  previewSMS,
  previewSMSPersonalized,
  sendSMS,
  sendSMSPersonalized,
} from "../../libs/dashboard";
import { loggedInUsers } from "../../store/store";
import { useFormik } from "formik";
import { count } from "sms-length";
import { validateSendSMSP } from "../../libs/helper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyMe } from "../notifyCook";

var CryptoJS = require("crypto-js");
const { TextArea } = Input;
const { Dragger } = Upload;

const Personalized = () => {
  const msgTypHolder = ["Promotional", "Transactional"];
  const [promo, setPromo] = useState([]);
  const [trans, setTrans] = useState([]);
  const [uploadId, setUploadId] = useState("");
  const [isPreviewBtn, setIsPreviewBtn] = useState(false);
  const [smsPreview, setSmsPreview] = useState({});
  const [flipSchedule, setFlipSchedule] = useState(false);
  const [smsRouteValue, setSmsRouteValue] = useState(null);
  const [loadings, setLoadings] = useState([]);

  const msgType = {
    Promotional: promo,
    Transactional: trans,
  };

  const [sIDs, setSIDs] = useState(msgType[msgTypHolder[0]]);
  const [senderID, setSenderID] = useState(msgType[msgTypHolder[0]][0]);

  const { userdata, senderIDTrans, senderIDPromo, trnsMsg } = loggedInUsers(
    (state) => state.auth
  );

  const setSenderIDTrans = loggedInUsers((state) => state.setSenderIDTrans);
  const setSenderIDPromo = loggedInUsers((state) => state.setSenderIDPromo);
  const setTrnsMsg = loggedInUsers((state) => state.setTrnsMsg);

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    getSenderid();
    // formik.setFieldValue("messageType", msgTypeS);
  }, []);

  const getSenderid = async () => {
    const getSID = await getAllSenderId({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data } = getSID;

    // getting status of Transactional SMS
    const smsUser = await getSMSUser({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data: datasms } = smsUser;
    const res = datasms.data.approvedForTransactionalSmses;
    setTrnsMsg(res);
    // console.log("Approved for Trans", trnsMsg);

    let sidvalue1 = data.data.promotional;
    let sidvalue2 = data.data.transactional;
    setPromo(sidvalue1);
    setTrans(sidvalue2);
  };

  const handleMsgTyp = (value) => {
    setSmsRouteValue(value);
    setSIDs(msgType[value]);
    setSenderID(msgType[value][0]);
    formik.setFieldValue("messageType", value.toLowerCase());
    formik.setFieldValue("sender_ID", msgType[value][0]);
    console.log();
  };
  const onSenderIDChange = (value) => {
    formik.setFieldValue("sender_ID", value);
    setSenderID(value);
  };

  const resetForm = () => {
    formik.resetForm();
    setIsPreviewBtn(false);
    setUploadId("");
    setSmsRouteValue(null);
    setSenderID(null);
  };

  // MODAL Starts Here
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = ({ id }) => {
    // console.log(id);
    if (!id) {
      handleCancel();
    } else {
      setConfirmLoading(true);
      setTimeout(() => {
        setIsModalOpen(false);
        setConfirmLoading(false);
      }, 1000);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setUploadId("");
  };

  const { RangePicker } = DatePicker;

  // Schedule date
  const onDateChange = (value, dateString) => {
    // console.log('Selected Time: ', value);
    console.log("Formatted Selected Time: ", dateString);
    formik.setFieldValue("isScheduled", true);
    formik.setFieldValue("scheduleDateTime", dateString);
  };

  const switchSchedule = () => {
    setFlipSchedule(!flipSchedule);
    if (flipSchedule === false) formik.setFieldValue("scheduleDateTime", "");
    formik.setFieldValue("isScheduled", !flipSchedule);
    formik.setFieldValue("flipSchedule", !flipSchedule);
  };
  // Modal Ends Here

  // Upload File starts here
  const headers = { authorization: `Bearer ${userD?.bearerToken}` };

  const props = {
    name: "file",
    multiple: false,
    action: `${process.env.REACT_APP_SMSMODULE_BASEURL}/uploads/uploadFileForPersonalizedMessage?userId=${userD?.email}`,
    headers,
    onChange(info) {
      const { status, response } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploadId(info.file.name);
        formik.setFieldValue("fileUploaded", true);
        formik.setFieldValue("uploadedFileName", response.data.uploadFileName);
        setTimeout(() => {
          handleOk({ id: info.file.name });
        }, 500);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        // console.log("response", response);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // End of upload

  const formik = useFormik({
    initialValues: {
      messageType: "",
      sender_ID: "",
      smsMessage: "",
      isScheduled: false,
      scheduleDateTime: "",
    },
    validate: validateSendSMSP,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = Object.assign(
        values,
        { userId: userD?.email || "" },
        { bearerToken: userD?.bearerToken || "" }
      );
      // console.log(values);
      // return
      try {
        if (!isPreviewBtn) {
          setConfirmLoading(true);
          const smsResp = await previewSMSPersonalized(values);
          const {
            data: { data },
          } = smsResp;
          setConfirmLoading(false);
          setIsPreviewBtn(true);
          setSmsPreview(data);
        } else if (isPreviewBtn) {
          setConfirmLoading(true);
          const smsResp = await sendSMSPersonalized(values);
          const {
            data: { data },
          } = smsResp;
          // console.log(data);
          const msg = `${
            values.isScheduled
              ? `Your SMS has been Scheduled for ${values.scheduleDateTime}`
              : "SMS sent successfully"
          }`;
          setConfirmLoading(false);
          toast.success(msg);
          resetForm();
          notifyMe(userD?.email, msg);
        }
      } catch (error) {
        console.error("Error Sending SMS");
      }
    },
  });

  return (
    <div>
      <h1 className="text-xl font-semibold ">Personalized SMS</h1>
      <div className="mt-4">
        <Card
          title="Compose Personalized Message"
          // extra={<a href="#">Add Contact</a>}
          bordered={false}
          style={{
            width: `70%`,
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-2 ">
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">SMS Route:</label>
                <Select
                  value={smsRouteValue}
                  style={{
                    width: 200,
                  }}
                  onChange={handleMsgTyp}
                  placeholder="Message Type"
                  options={msgTypHolder.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  disabled={isPreviewBtn ? true : false}
                />
              </div>
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">Sender ID:</label>
                <Select
                  style={{
                    width: 200,
                  }}
                  value={senderID}
                  onChange={onSenderIDChange}
                  options={sIDs.map((ids) => ({
                    label: ids,
                    value: ids,
                  }))}
                  allowClear="true"
                  placeholder="Sender ID"
                  disabled={isPreviewBtn ? true : false}
                />
              </div>
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">Recipients:</label>
                {isPreviewBtn ? (
                  <div>
                    <p className=" font-semibold pt-1 text-gray-600">
                      {smsPreview.recipientDetails.totalRecipients} recipients
                      || {smsPreview.recipientDetails.invalidRecipients} invalid
                      recipients
                    </p>
                  </div>
                ) : (
                  <div>
                    {uploadId ? (
                      <div>
                        <p className=" font-semibold pt-1 text-gray-600">
                          {uploadId}
                        </p>
                      </div>
                    ) : (
                      <Button type="primary bg-indigo-500" onClick={showModal}>
                        Upload Contact
                      </Button>
                    )}

                    {/* Modal Starts Here */}
                    <Modal
                      title="Upload Contact"
                      open={isModalOpen}
                      onOk={handleOk}
                      confirmLoading={confirmLoading}
                      onCancel={handleCancel}
                    >
                      <div>
                        <p className="text-xs p-4 justify-center">
                          This method allows you add multiple phone numbers in a
                          file
                          <br /> at once, with additional information (e.g.
                          names, email, etc).
                          <br />
                          <span className="font-semibold">
                            Phone number field is required and must have a
                            header*
                          </span>
                        </p>
                        <div className="flex flex-col justify-between bg-white p-4 rounded-md min-h-[250px] ">
                          <p></p>

                          <div>
                            <Dragger
                              {...props}
                              className="w-72"
                              accept=".txt, .csv, .xlsx, .xls"
                            >
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                              </p>
                              <p className="ant-upload-text text-sm">
                                Click or drag file to this area to upload
                                <br />
                                <span className="text-xs font-semibold">
                                  Allowed extensions ('.txt', '.csv', '.xlsx',
                                  '.xls')
                                </span>
                              </p>
                            </Dragger>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* Modal Ends Here */}
                  </div>
                )}
              </div>
              {isPreviewBtn && (
                <div className="flex flex-auto gap-4">
                  <label className="w-[90px]">Sample recipient:</label>
                  <div className="flex-row">
                    <p className=" font-semibold pt-1 text-gray-600">
                      {smsPreview.sampleMessage.recipient}
                    </p>
                  </div>
                </div>
              )}
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">
                  {isPreviewBtn ? "Sample Message" : "Message"}:
                </label>
                <div className="flex-row">
                  {isPreviewBtn ? (
                    <div>
                      <TextArea
                        style={{
                          height: 120,
                          resize: "none",
                        }}
                        value={smsPreview.sampleMessage.text}
                        className="w-80 font-poppins"
                        required="required"
                        disabled={true}
                      />
                    </div>
                  ) : (
                    <div>
                      <TextArea
                        style={{
                          height: 120,
                          resize: "none",
                        }}
                        placeholder="Hello {surname} {firstName}, we are delighted to have you join us.\nYour current balance is {balance}."
                        className="w-80 font-poppins"
                        {...formik.getFieldProps("smsMessage")}
                        required="required"
                        disabled={false}
                      />
                    </div>
                  )}
                  <p className="text-right text-gray-600 text-xs">
                    {count(formik.getFieldProps("smsMessage").value).length}/
                    {count(formik.getFieldProps("smsMessage").value).messages}
                  </p>
                </div>
              </div>
              <div className="flex flex-auto gap-4">
                <label className="w-[90px]">Schedule:</label>
                <div className="flex flex-col">
                  <div>
                    <Switch
                      checked={flipSchedule}
                      checkedChildren="ON"
                      unCheckedChildren="OFF"
                      onChange={switchSchedule}
                      disabled={isPreviewBtn ? true : false}
                      className="w-14 font-poppins bg-indigo-500"
                    />
                  </div>
                  <div className="pt-3 transition-all ease-in">
                    {flipSchedule ? (
                      <DatePicker
                        showTime
                        onChange={onDateChange}
                        disabled={isPreviewBtn ? true : false}
                        allowClear
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex  flex-auto pb-2 ">
                <label className="w-[120px]"></label>

                <Button
                  type="primary"
                  onClick={formik.handleSubmit}
                  disabled={uploadId ? false : true}
                  loading={confirmLoading}
                  className={`rounded-1xl  ${
                    uploadId
                      ? "bg-indigo-500 text-decoration-none fs-8 hover:scale-105 duration-300 text-white"
                      : "bg-gray-400 text-gray-700"
                  }   font-medium w-22  text-center `}
                >
                  {isPreviewBtn ? "Send" : "Proceed"}
                </Button>
                <label className="w-[20px]"></label>
                <button
                  className="rounded-2xl px-3 py-2 bg-red-500 text-white font-medium w-20  text-center text-decoration-none fs-8 hover:scale-105 duration-300"
                  onClick={resetForm}
                  type="button"
                >
                  Reset
                </button>
              </div>
            </div>
          </form>
        </Card>
      </div>
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default Personalized;
