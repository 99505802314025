import React, { useState } from "react";
import { useFormik } from "formik";
import { Card, Input, Button, Modal } from "antd";
import { BiWalletAlt } from "react-icons/bi";
import { loggedInUsers } from "../../store/store";
import { validateTopUp } from "../../libs/helper";
import { ToastContainer, toast } from "react-toastify";
import { Circles } from "react-loading-icons";
import "react-toastify/dist/ReactToastify.css";
import { fundWallet } from "../../libs/dashboard";
import { socket } from "../../libs/socketioClient";
import styles from "../../index";
import {
  formatNumberAsCurrency,
  socketMessageDecrypt,
} from "../../libs/decrypt";
import { notifyMe } from "../notifyCook";
import Cookies from "universal-cookie";

var CryptoJS = require("crypto-js");
// cookie
const cookie = new Cookies();

const Wallet = () => {
  const [modal, contextHolder] = Modal.useModal();

  // Fetch data from store.
  const { userdata, akpaEgo, active } = loggedInUsers((state) => state.auth);
  const setAkpaEgo = loggedInUsers((state) => state.setAkpaEgo);

  const { notifica8 } = loggedInUsers((state) => state.notifica8);
  const setNotifica8 = loggedInUsers((state) => state.setNotifica8);

  const [proceedPayment, setProceedPayment] = useState(false);
  const [proceedCheckout, setProceedCheckout] = useState(false);
  const [feesAdded, setFeesAdded] = useState("");
  const [initialAmount, setInitialAmount] = useState("");
  const [totalAmountToPay, setTotalAmountToPay] = useState("");
  const [paymentInfo, setPaymentInfo] = useState({});
  const [completed, setCompleted] = useState("");
  const [amount, setAmount] = useState("");

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));
  var getWallet = CryptoJS.AES.decrypt(
    akpaEgo,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var wallet = JSON.parse(getWallet.toString(CryptoJS.enc.Utf8));

  // Fetch data from store.
  //Modal Open
  const countDown = ({ amount, balanceAfter }) => {
    let secondsToGo = 5;
    const instance = modal.success({
      title: "Payment Successful",
      content: `Transaction of ${amount} was successful, your new balance is ${balanceAfter}.`,
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      instance.update({
        content: `Transaction of ${amount} was successful, your new balance is ${balanceAfter}.`,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      instance.destroy();
    }, secondsToGo * 1000);

    // navigate("/auth/otp");
  };

  const switchMode = () => {
    setProceedPayment((prevProceedPayment) => !prevProceedPayment);
  };
  const cancelPay = () => {
    setProceedCheckout(false);
    setProceedPayment(false);
    setFeesAdded("");
    setInitialAmount("");
    setTotalAmountToPay("");
    setPaymentInfo();
  };

  const bal = formatNumberAsCurrency(wallet?.balance);

  const proceedToPay = async () => {
    setProceedCheckout((prevProceedCheckout) => !prevProceedCheckout);
    window.open(`${paymentInfo.authorization_url}`, "_blank");

    socket.on("DashboardNotif:Wallet_Deposit", (messageEnc) => {
      let message = socketMessageDecrypt(messageEnc);
      if (userD?.email === message.data.walletUserId && active === true) {
        const amount = message?.data.amount;
        const balanceAfter = message?.data.balanceAfter;
        var newWalletBalance = { ...wallet, balance: balanceAfter };
        // console.log(newWalletBalance);
        const bal = formatNumberAsCurrency(balanceAfter);
        const amt = formatNumberAsCurrency(amount);
        // console.log(bal,amt);
        countDown({ amount: amt, balanceAfter: bal });
        setCompleted("completed");
        const msg = `Transaction of ${amt} was successful, your new balance is ${bal}.`;
        notifyMe(userD?.email, msg);

        var walletEncry = CryptoJS.AES.encrypt(
          JSON.stringify(newWalletBalance),
          process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
        ).toString();
        setAkpaEgo(walletEncry);
        // cancelPay();
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      amount: "",
      method: "paystack",
    },
    validate: validateTopUp,
    // validate: verifyTopUp,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = Object.assign(
        values,
        { userId: userD?.email || "" },
        { bearerToken: userD?.bearerToken || "" }
      );
      // console.log(values);
      try {
        const fundWalt = await fundWallet(values);
        console.log(fundWalt, 'checkbacend');
        const {
          data: { data },
        } = fundWalt;
        // console.log(data);
        setFeesAdded(data.feesAdded);
        setInitialAmount(data.initialAmount);
        setTotalAmountToPay(data.totalAmountToPay);
        setPaymentInfo(data.paymentInfo);
        switchMode();
        // toast.success(authUser.data.message);
        // console.log(authUser.data.message);
      } catch (error) {
        console.error("Error Funding Wallet...");
        // console.log(values);
      }
    },
  });

  return (
    <div className="">
      <h3 className="text-xl font-semibold mb-4">
        Fund Wallet - Broadbased SMS Solution
      </h3>
      <div className="flex gap-5 flex-col ss:flex-row md:flex-row">
        {proceedPayment ? (
          <Card
            title={
              proceedCheckout ? "Confirming Payment " : "Proceed to Payment"
            }
            bordered={false}
            style={{
              width: 500,
            }}
          >
            {proceedCheckout ? (
              <div>
                {completed ? (
                  <div className="gap-2">
                    <p className="pt-2 text-lg"> Payment completed</p>
                    <p className="pt-2 text-lg">
                      Your new balance is{" "}
                      <span className="font-bold">
                        {wallet?.currency} {bal}
                      </span>
                    </p>
                    <button
                      className="rounded-2xl px-3 py-2 mt-4 justify-center bg-indigo-500 text-white font-medium w-30  text-center text-decoration-none fs-7 hover:scale-105 duration-300"
                      onClick={cancelPay}
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <div>
                    <p className="pt-2 text-lg">
                      Getting payment confirmation...
                    </p>
                    <div className="flex justify-center p-2 ">
                      <Circles stroke="#ed4c07" height="5rem" />
                    </div>
                  </div>
                )}

                {/* <Button onClick={countDown}>Success</Button> */}
                {contextHolder}
              </div>
            ) : (
              <div>
                <div className="text-lg gap-2">
                  <p className="pt-2">
                    Top up Amount : {formatNumberAsCurrency(initialAmount)}
                  </p>
                  <p className="pt-2">Vat Added : 7.5% VAT</p>
                  <p className="pt-2">
                    Total Amount : {formatNumberAsCurrency(totalAmountToPay)}
                  </p>
                </div>

                <div className="justify-center items-center flex gap-2 pt-7">
                  <button
                    className="rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-30  text-center text-decoration-none fs-7 hover:scale-105 duration-300"
                    onClick={proceedToPay}
                  >
                    Proceed
                  </button>
                  <button
                    className="rounded-2xl px-3 py-2 bg-red-500 text-white font-medium w-25  text-center text-decoration-none fs-7 hover:scale-105 duration-300"
                    onClick={cancelPay}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </Card>
        ) : (
          <Card
            title="Fund Wallet with Debit Card"
            bordered={false}
            style={{
              width: 500,
            }}
          >
            <p>
              You are about to add funds to your account. The minimum amount you
              can add is <span className="font-bold">NGN1,000.00</span>
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="flex-1 gap-3 flex items-center sm:flex-row py-3">
                <p>Amount </p>

                <Input
                  {...formik.getFieldProps("amount")}
                  addonBefore={<BiWalletAlt />}
                  addonAfter="NGN"
                  defaultValue="1000"
                  width="80%"
                  required="required"
                  // value={amount}
                  // onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <p className="font-semibold pb-2">*Subject to 7.5% VAT</p>
              {/* <button type="submit">Fund Account</button> */}
              <div className="justify-center items-center flex">
                <button
                  className={`
                 ${!formik.values.amount ? 'bg-gray-400' : 'bg-indigo-500'}
                   rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-30  text-center text-decoration-none fs-7 hover:scale-105 duration-300`}
                  type="submit"
                  // disabled={!amount}  ${!amount ? 'bg-gray-400' : 'bg-indigo-500'} 
                  disabled={!formik.values.amount}
                >
                  Fund Wallet
                </button>
              </div>
            </form>
          </Card>
        )}

        {/* <Card
          title="Fund Wallet with Bank Transfer"
          bordered={false}
          style={{
            width: 400,
          }}
        >
          <p>
            Please remember to include your username in the comment/remark when
            you make payments.
          </p>
        </Card> */}
      </div>
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default Wallet;
