import axios from "axios";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";

export async function registerNewUser(credentials) {
  try {
    const { firstName, lastName, email, phoneNumber, address, password } =
      credentials;
    const payload = {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      password,
    };
    const auth = {
      username: process.env.REACT_APP_USERMODULE_AUTHUSER,
      password: process.env.REACT_APP_USERMODULE_AUTHPASS,
    };
    const callResp = await axios.post(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/register`,
      payload,
      { auth }
    );
    return callResp;
  } catch (error) {
    console.error(error.response.data.message);
    toast.error(error.response.data.message);
  }
}
// export async function registerNewUser({
//   firstName,
//   lastName,
//   email,
//   phoneNumber,
//   address,
//   password,
// }) {
//   try {
//     const payload = {
//       firstName,
//       lastName,
//       email,
//       phoneNumber,
//       address,
//       password,
//     };
//     const auth = {
//       username: process.env.REACT_APP_USERMODULE_AUTHUSER,
//       password: process.env.REACT_APP_USERMODULE_AUTHPASS,
//     };
//     const callResp = await axios.post(
//       `${process.env.REACT_APP_USERMODULE_BASEURL}/user/register`,
//       payload,
//       { auth }
//     );

//     return callResp;
//   } catch (error) {
//     console.error(error.response.data.message);
//     toast.error(error.response.data.message);
//   }
// }

export async function authenticateUser({ email, password }) {
  try {
    const payload = { email, password };
    const auth = {
      username: process.env.REACT_APP_USERMODULE_AUTHUSER,
      password: process.env.REACT_APP_USERMODULE_AUTHPASS,
    };
    const callResp = await axios.post(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/authenticate`,
      payload,
      { auth }
    );
    return callResp;
  } catch (error) {
    console.error("Incorrect Password");
    if (error.response) {
      // console.error(error.response.data)
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function resetPassword({ email, currentPassword, newPassword }) {
  try {
    const payload = { email, currentPassword, newPassword };
    const auth = {
      username: process.env.REACT_APP_USERMODULE_AUTHUSER,
      password: process.env.REACT_APP_USERMODULE_AUTHPASS,
    };
    const callResp = await axios.put(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/resetPassword`,
      payload,
      { auth }
    );
    return callResp;
  } catch (error) {
    console.error("Incorrect Password");
    if (error.response) {
      // console.error(error.response.data)
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function verificationCode({ email, verificationCode }) {
  try {
    const payload = { email, verificationCode };
    const auth = {
      username: process.env.REACT_APP_USERMODULE_AUTHUSER,
      password: process.env.REACT_APP_USERMODULE_AUTHPASS,
    };
    const callResp = await axios.post(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/completeRegistration`,
      payload,
      { auth }
    );
    return callResp;
  } catch (error) {
    console.error("Error verifying  OTP");
    if (error.response) {
      // toast.error(error.response.data.message);
      console.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function resendOTP({ email }) {
  try {
    console.log(email);
    const payload = { email };
    const auth = {
      username: process.env.REACT_APP_USERMODULE_AUTHUSER,
      password: process.env.REACT_APP_USERMODULE_AUTHPASS,
    };
    const callResp = await axios.post(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/retriggerVerificationCode`,
      payload,
      { auth }
    );

    return callResp;
  } catch (error) {
    console.error(error.response.data.message);
    toast.error(error.response.data.message);
  }
}

export async function authenticateEmail(email) {
  try {
    const payload = { email };
    const auth = {
      username: process.env.REACT_APP_USERMODULE_AUTHUSER,
      password: process.env.REACT_APP_USERMODULE_AUTHPASS,
    };
    const callResp = await axios.get(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/checkEmailExistence?email=${email}`,
      { auth }
    );
    // const {data} = callResp
    // toast.info(`${data.message} ${data.data.exist}`);
    return callResp;
  } catch (error) {
    console.error("Error authenticating email");
    if (error.response) {
      console.error(error.response.data);
      toast.error(error.response.data.message);
    }
    console.error(error);
  }
}

export async function login({ email, password }) {
  const payload = { email, password };
  const auth = {
    username: process.env.REACT_APP_USERMODULE_AUTHUSER,
    password: process.env.REACT_APP_USERMODULE_AUTHPASS,
  };
  try {
    const data = await axios.post(
      `${process.env.REACT_APP_USERMODULE_BASEURL}/user/authenticate`,
      payload,
      { auth }
    );
    // console.log(data);
    return data;
  } catch (error) {
    return Promise.reject({ error: "Login failed!" });
  }
}
// (
//     async()=>{
//         await authenticateUser({email:"etopeojo1@yahoo.com", password:"etopeojo@yahoo.comm"})
//     }
// )()
