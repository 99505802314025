import { Navigate } from "react-router-dom";
import { loggedInUsers } from "../store/store";
import CryptoJS from "crypto-js";
// var CryptoJS = require("crypto-js");

export function AuthorizeUser({ children }) {
  //Get user email
  const userD = Decrypt();

  // console.log(userD);

  if (!userD) {
    return <Navigate to={"/"} replace={true}></Navigate>;
  }

  return children;
}

export const ProtectRoute = ({ children }) => {
  //   const username = useAuthStore.getState().auth.username;
  //   if (!username) {
  //     return <Navigate to={"/"} replace={true}></Navigate>;
  //   }
  return children;
};

export function Decrypt() {
  // Fetch data from store.
  const { userdata } = loggedInUsers((state) => state.auth);

  //   Decrypt
  if (userdata) {
    var getUser = CryptoJS.AES.decrypt(
      userdata,
      process.env.REACT_APP_SOCKET_ENCRYPT_KEY
    );
    var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));
    // console.log(userD);
  }
  // console.log(userdata.email);
  return userD?.email;
}
