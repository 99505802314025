import { useState } from "react";
import { Modal } from "antd";
import { Card, Tabs, message } from "antd";
import { FiEye, FiCopy } from "react-icons/fi";
import { authenticateUser, resetPassword } from "../libs";
import { loggedInUsers } from "../store/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate } from "react-router-dom";

var CryptoJS = require("crypto-js");

const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { userdata, akpaEgo, active } = loggedInUsers((state) => state.auth);
  const [pwd, setPwd] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cnewPass, setCnewPass] = useState("");
  const [msg, setMsg] = useState([]);

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    try {
      if (pwd) {
        setConfirmLoading(true);
        console.log(pwd);
        const authUser = await authenticateUser({
          email: userD?.email,
          password: pwd,
        });
        toast.success(authUser.data.message);
        // console.log(authUser.data.message);
        setApiKey(authUser.data.data.bearerToken);
        setTimeout(() => {
          setIsModalOpen(false);
          setConfirmLoading(false);
          setPwd("");
        }, 1000);
      }
    } catch (error) {
      console.error("Error Authenticating User Log");
      setConfirmLoading(false);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const copyText = () => {
    // Get the text field
    var copy = document.getElementById("api");

    // Select the text field
    copy.select();
    copy.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copy.value);

    // Alert the copied text
    toast.success("Copied to clipboard");
    // alert("Copied the text: " + copy.value);
  };

  function showPass() {
    var x = document.getElementById("api");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  const handleChngPass = async () => {
    if (oldPass === "" || newPass === "" || cnewPass === "") {
      toast.error("Empty fields");
    } else if (newPass !== cnewPass) {
      toast.error("Password does not match");
    } else {
      try {
        const authUser = await resetPassword({
          email: userD?.email,
          currentPassword: oldPass,
          newPassword: newPass,
        });
        toast.success(authUser.data.message);
      } catch (error) {
        console.error("Error Changing Password");
      }
    }
  };
  const settingApi = () => {
    return (
      <>
        <div className="h-[300px] pt-5">
          <p className="font-bold text-center p-2 text-xl">
            API Configuration - Live Mode
          </p>
          <div className="flex gap-3 items-center content-center mt-3">
            <p>Live Secret Key:</p>
            {!apiKey ? (
              <>
                <input
                  className="w-80 rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 focus:border-blue-800 focus:bg-gray-100 focus:outline-none"
                  type="password"
                  value="pk_live_e94149912938b2c69e605087b010f13586344fbf"
                />
                <FiEye className="text-lg cursor-pointer" onClick={showModal} />
              </>
            ) : (
              <>
                <input
                  className="w-80  rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 pr-10 focus:border-blue-800 focus:bg-gray-100 focus:outline-none"
                  type="text"
                  id="api"
                  value={apiKey}
                />
                <FiCopy className="text-lg cursor-pointer" onClick={copyText} />
                <FiEye className="text-lg cursor-pointer" onClick={showPass} />
              </>
            )}
          </div>
          <Modal
            title="Enter Password"
            open={isModalOpen}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
          >
            <div className="flex flex-col justify-center">
              <p className="text-sm p-4 ">
                For security reasons, enter your password to view your live
                secret keys.
              </p>
              <input
                className=" w-80 rounded-lg border-gray-800 bg-gray-100 m-2 p-2 focus:border-blue-800 focus:bg-gray-100 focus:outline-none"
                type="password"
                placeholder="Enter your account Password"
                required
                onChange={(e) => setPwd(e.target.value)}
              />
            </div>
          </Modal>
        </div>
      </>
    );
  };
  const changPass = () => {
    return (
      <>
        <div className="h-[320px] pt-3 m-0">
          <p className="font-semibold text-center p-2 text-xl">
            Change Password
          </p>
          <div className="flex flex-col gap-3 mt-3 w-[470px] ml-10">
            <div className="flex justify-between items-center gap-3">
              <p>Old Password:</p>
              <input
                className="w-80 rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 focus:border-blue-800 focus:bg-gray-200 focus:outline-none"
                type="password"
                placeholder="Old Password"
                onChange={(e) => setOldPass(e.target.value)}
              />
              {/* <p>{msg[0]}</p> */}
            </div>
            <div className="flex justify-between items-center gap-3">
              <p>New Password:</p>
              <input
                className="w-80 rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 focus:border-blue-800 focus:bg-gray-200 focus:outline-none"
                type="password"
                placeholder="New Password"
                onChange={(e) => setNewPass(e.target.value)}
              />
            </div>
            <div className="flex justify-between items-center gap-3">
              <p>Confirm Password:</p>
              <input
                className="w-80 rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 focus:border-blue-800 focus:bg-gray-200 focus:outline-none"
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => setCnewPass(e.target.value)}
              />
            </div>
            <div className="m-auto mt-2">
              <button
                type="Submit"
                onClick={handleChngPass}
                className="rounded-2xl px-3 py-2 bg-indigo-500 text-white font-medium w-24  text-center text-decoration-none fs-8 hover:scale-105 duration-300"
              >
                Change
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  const items = [
    {
      key: "1",
      label: `Settings`,
      children: settingApi(),
    },
    {
      key: "2",
      label: `Change Password`,
      children: changPass(),
    },
  ];

  return (
    <div>
      <Card
        style={{
          width: 700,
          minHeight: 250,
        }}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Card>
      {/* <p className="text-xl font-semibold mb-4">Settings</p>
      <Card style={{ width: `80%`, height: 300 }}>
        <p className="font-bold text-center p-2 text-xl">
          API Configuration - Live Mode
        </p>
        <div className="flex gap-3 items-center content-center mt-3">
          <p>Live Secret Key:</p>
          {!apiKey ? (
            <>
              <input
                className="w-80 rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 focus:border-blue-800 focus:bg-gray-100 focus:outline-none"
                type="password"
                value="pk_live_e94149912938b2c69e605087b010f13586344fbf"
              />
              <FiEye className="text-lg cursor-pointer" onClick={showModal} />
            </>
          ) : (
            <>
              <input
                className="w-80  rounded-lg border-gray-800 bg-gray-100 mt-2 p-2 pr-10 focus:border-blue-800 focus:bg-gray-100 focus:outline-none"
                type="text"
                id="api"
                value={apiKey}
              />
              <FiCopy className="text-lg cursor-pointer" onClick={copyText} />
              <FiEye className="text-lg cursor-pointer" onClick={showPass} />
            </>
          )}
        </div>
        <Modal
          title="Enter Password"
          open={isModalOpen}
          onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
        >
          <div className="flex flex-col justify-center">
            <p className="text-sm p-4 ">
              For security reasons, enter your password to view your live secret
              keys.
            </p>
            <input
              className=" w-80 rounded-lg border-gray-800 bg-gray-100 m-2 p-2 focus:border-blue-800 focus:bg-gray-100 focus:outline-none"
              type="password"
              placeholder="Enter your account Password"
              required
              onChange={(e) => setPwd(e.target.value)}
            />
          </div>
        </Modal>
      </Card> */}
      <ToastContainer autoClose={3000} theme="colored" newestOnTop={true} />
    </div>
  );
};

export default Settings;
