import { create } from "zustand";
import { persist } from "zustand/middleware";

let loggedInUser = (set) => ({
  auth: {
    userdata: {},
    active: false,
    akpaEgo: {},
    socket: "",
    senderIDPromo: [],
    senderIDTrans: [],
    trnsMsg: false,
  },
  setActiveUser: (user) =>
    set((state) => ({ auth: { ...state.auth, userdata: user } })),
  toggleActiveMode: (activeStatus) =>
    set((state) => ({ auth: { ...state.auth, active: activeStatus } })),
  toggleSocketMode: (socketMode) =>
    set((state) => ({ auth: { ...state.auth, socket: socketMode } })),
  setAkpaEgo: (ego) =>
    set((state) => ({ auth: { ...state.auth, akpaEgo: ego } })),
  setSenderIDTrans: (SIDs) =>
    set((state) => ({ auth: { ...state.auth, senderIDTrans: SIDs } })),
  setSenderIDPromo: (SIDs) =>
    set((state) => ({ auth: { ...state.auth, senderIDPromo: SIDs } })),
  setTrnsMsg: (SIDs) =>
    set((state) => ({ auth: { ...state.auth, trnsMsg: SIDs } })),

  notifica8: [],
  setNotifica8: (note) =>
    set((state) => ({ notifica8: [...state.notifica8, note] })),
});

loggedInUser = persist(loggedInUser, { name: "user" });

// peopleStore = devtools(peopleStore)

// export const useSettingsStore = create(settingsStore)
export const loggedInUsers = create(loggedInUser);

// var CryptoJS = require("crypto-js");

//   var data = [{ id: 1000 }, { id: 20001 }, { name: "Henry" }];

//   // Encrypt
//   var ciphertext = CryptoJS.AES.encrypt(
//     JSON.stringify(data),
//     "secret key 123"
//   ).toString();

//   console.log(ciphertext);
//   console.log(ciphertext[2]);
//   console.log("encrypt");

//   // Decrypt
//   var bytes = CryptoJS.AES.decrypt(ciphertext, "secret key 123");
//   var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

//   // console.log(decryptedData); // [{id: 1}, {id: 2}]
//   console.log(decryptedData[0]); // [{id: 1}, {id: 2}]
//   console.log(decryptedData[1]); // [{id: 1}, {id: 2}]
//   console.log(decryptedData[2]); // [{id: 1}, {id: 2}]
