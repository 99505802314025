import React, { useEffect, useState } from "react";
import styles, { layout } from "../style";
import { BsWallet2, BsWalletFill } from "react-icons/bs";
import { FaSms } from "react-icons/fa";
import { Oval } from "react-loading-icons";
import { loggedInUsers } from "../store/store";
import { formatNumberAsCurrency, socketMessageDecrypt } from "../libs/decrypt";
import { socket } from "../libs/socketioClient";
import Cookies from "universal-cookie";
import { sMsDay, sMsMonth } from "../libs/dashboard";
import { formatNumber } from "../libs/helper";

var CryptoJS = require("crypto-js");

const Dashboard = () => {
  // Fetch data from store.
  const { userdata, akpaEgo, active } = loggedInUsers((state) => state.auth);
  const { notifica8 } = loggedInUsers((state) => state.notifica8);
  const setAkpaEgo = loggedInUsers((state) => state.setAkpaEgo);

  const [smsD, setSmsD] = useState("");
  const [smsM, setSmsM] = useState("");

  // Decrypt
  var getUser = CryptoJS.AES.decrypt(
    userdata,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var userD = JSON.parse(getUser.toString(CryptoJS.enc.Utf8));
  var getWallet = CryptoJS.AES.decrypt(
    akpaEgo,
    process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
  );
  var wallet = JSON.parse(getWallet.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    getSMSCountD();
    getSMSCountM();
  }, []);

  // sms count Month
  const getSMSCountM = async () => {
    const smsMonth = await sMsMonth({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data } = smsMonth;
    setSmsM(`${data.data.totalCount}`);
  };
  // sms count Day
  const getSMSCountD = async () => {
    const smsDay = await sMsDay({
      email: userD?.email,
      bearerToken: userD?.bearerToken,
    });
    const { data } = smsDay;
    setSmsD(`${data.data.totalCount}`);
  };

  // Cookie
  const cookie = new Cookies();
  const getCnotify = cookie.get(userD?.email);

  const bal = formatNumberAsCurrency(wallet?.balance);

  socket.on("DashboardNotif:Wallet_Balance_Update", (messageEnc) => {
    let message = socketMessageDecrypt(messageEnc);
    // console.log(message);
    if (userD?.email === message.data.userId && active === true) {
      const balance = message?.data.balance;
      var newWalletBalance = { ...wallet, balance: balance };

      // Encrypt
      var walletEncry = CryptoJS.AES.encrypt(
        JSON.stringify(newWalletBalance),
        process.env.REACT_APP_SOCKET_ENCRYPTION_KEY
      ).toString();
      setAkpaEgo(walletEncry);
      // cancelPay();
    }
  });

  return (
    <div>
      <h3 className="text-xl font-semibold pb-2">DashBoard</h3>
      {/* <div className="dboard flex md:flex-row sm:flex-row flex-col justify-between  gap-3"> */}
      <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-3 ">
        <div className="flex flex-grow-1 justify-between bg-white p-4 rounded-md min-h-[200px] drop-shadow-lg hover:drop-shadow-none transition">
          <div>
            <p className="font-semibold text-xl leading-5  py-3 ">
              Wallet Balance
            </p>
            <h4 className="font-bold py-3 text-lg">
              {wallet?.currency} {bal}
            </h4>
          </div>
          <div className="flex flex-col items-end">
            <h6 className="flex text-orange-600 p-2 text-2xl">
              <BsWalletFill />
            </h6>
            <p className="mb-0 text-[10px] font-medium text-gray-900">
              {/* Total wallet balance */}
            </p>
          </div>
        </div>
        <div className="flex flex-grow-1 justify-between bg-white p-4 rounded-md min-h-[200px] drop-shadow-lg hover:drop-shadow-none transition">
          <div>
            <p className="font-semibold text-xl py-3 leading-5 ">
              Total SMSes Today
            </p>
            <h4 className=" flex font-bold py-3 text-lg items-center">
              {smsD ? (
                formatNumber(smsD)
              ) : (
                <Oval stroke="#ed4c07" height="1.2rem" />
              )}{" "}
              SMSes
            </h4>
          </div>
          <div className="flex flex-col items-end">
            <h6 className="flex text-orange-600 p-2 text-2xl">
              <FaSms />
            </h6>
            <p className="mb-0 text-[10px] font-medium text-gray-900">
              {/* Total wallet balance */}
            </p>
          </div>
        </div>
        <div className="flex flex-grow-1 justify-between bg-white p-4 rounded-md min-h-[200px] drop-shadow-lg hover:drop-shadow-none transition">
          <div>
            <p className="font-semibold text-[18px] py-3 leading-5 whitespace-normal ">
              Total SMSes This Month
            </p>
            <h4 className="flex font-bold py-3 text-lg items-center">
              {smsM ? (
                formatNumber(smsM)
              ) : (
                <Oval stroke="#ed4c07" height="1.2rem" />
              )}{" "}
              SMSes
            </h4>
          </div>
          <div className="flex flex-col items-end">
            <h6 className="flex text-orange-600 p-2 text-2xl">
              <FaSms />
            </h6>
            <p className="mb-0 text-[10px] font-medium text-gray-900">
              {/* Total wallet balance */}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="text-xl font-semibold pb-2">
          <h2>Recent Activities</h2>
        </div>
        <div className="bg-white rounded-lg drop-shadow-sm">
          <div className="p-4">
            {getCnotify?.map((value, index) => {
              return (
                <div
                  className="border-solid border-b-[1px] pb-2 pt-2"
                  key={index}
                >
                  <p>{value || "No recent activities found"}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
